import * as React from 'react';

class FocusOneTextBox extends React.PureComponent<any> {

    constructor(props: any) {
        super(props);

        this.handleMessageDescript = this.handleMessageDescript.bind(this);
    }

    handleMessageDescript(e: any) {

        this.props.changeDescription(e.target.value, this.props.selectLang);
    }

    render() {
        return (
            <div>
                <textarea className="form-control" defaultValue={this.props.messageDescription} onChange={this.handleMessageDescript}></textarea>
            </div>
            )

        };
}

export default FocusOneTextBox