import * as React from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import * as FocusOneMessageTabs from '../store/FocusOneMessageTabsData';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as Models from '../models/Models';
import { Spinner } from 'reactstrap';
import EditorComponent from './EditorComponent';
import FocusOneTextBox from './FocusOneTextBox';

type FocusOneMessageTabsProps =
    FocusOneMessageTabs.FocusOneMessageTabsState
    & typeof FocusOneMessageTabs.actionCreators;


class FocusOneMessageEditTabs extends React.PureComponent<any, {
    activeTab: number,
    setActiveTab: number,
    messageCode: string,
    messageRaw: string,
    messageList: Models.MessageTabsEdit[],
    messageDesc: Models.MessageTabsEditDescription[]
}, FocusOneMessageTabsProps> {

    langmodel: Models.focusoneLanguage | undefined;
    message: Models.MessageTabsEdit[] | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            activeTab: 1,
            setActiveTab: 1,
            messageList: [],
            messageDesc: [],
            messageCode: '',
            messageRaw: ''
        }

        this.updateContent = this.updateContent.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
    }

    public toggle(tab: number) {

        if (this.state.activeTab != tab) {
            this.setState({
                setActiveTab: tab,
                activeTab: tab
            });
        }
    }

    public updateContent(content: any, lang: any) {

        if (this.state.messageList.length > 0) {

            var rawMessage = this.state.messageList;
            var index = rawMessage.findIndex(i => i.language_id == lang);            

            if (index > -1) {

                rawMessage[index].message_document = content
                this.setState({
                    messageList: rawMessage
                })

            } else {
                var mapping: Models.MessageTabsEdit = {
                    language_id: lang,
                    message_document: content
                };

                var messageMepping = this.state.messageList;
                messageMepping.push(mapping);

                this.setState({
                    messageList: messageMepping
                })
            }            

        } else {
            var mapping: Models.MessageTabsEdit = {
                language_id: lang,
                message_document: content
            };

            var messageMepping = this.state.messageList;
            messageMepping.push(mapping);

            this.setState({
                messageList: messageMepping
            })
        }              

        this.props.messageContent(this.state.messageList);
    }


    handleDescription(desc: any, lang: any) {        

        if (this.state.messageDesc.length > 0) {

            var rawDesc = this.state.messageDesc;
            var index = rawDesc.findIndex(i => i.language_id == lang);

            if (index > -1) {
                rawDesc[index].message_description = desc;
                this.setState({
                    messageDesc: rawDesc
                });
            } else {
                var mepping: Models.MessageTabsEditDescription = {
                    language_id: lang,
                    message_description: desc
                }

                var descMepping = this.state.messageDesc;
                descMepping.push(mepping);

                this.setState({
                    messageDesc: descMepping
                });
            }

        } else {
            var mepping: Models.MessageTabsEditDescription = {
                language_id: lang,
                message_description: desc
            }

            var descMepping = this.state.messageDesc;
            descMepping.push(mepping);

            this.setState({
                messageDesc: descMepping
            });
        }

        this.props.messageDesc(this.state.messageDesc);
    }

    componentDidMount() {
        
        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);
            LANG = this.langmodel!.language_id;
        }

        this.props.requestFocusOneMessageLabs(this.props.messageCode, "GET", "", null, LANG);        
    }

    render() {

        if (!this.props.isLoading) {
            this.props.messageBody(this.props.message);
        }

        console.log(this.props.message);

        return (
            <React.Fragment>
                {this.props.isLoading &&
                    <div>
                        <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />
                    </div>
                }

                {!this.props.isLoading &&
                    <div>
                        <Nav tabs>
                        {this.props.message.map((item: FocusOneMessageTabs.FocusOneMessage, index: any) =>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === index + 1 })}
                                    onClick={() => { this.toggle(index + 1); }}
                                    style={{ cursor: "pointer" }}>
                                    {item.language_id}
                                </NavLink>
                            </NavItem>
                        )}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                        {this.props.message.map((item: FocusOneMessageTabs.FocusOneMessage, index: any) =>
                            <TabPane tabId={index + 1} className="mt-2">
                                <Row>
                                    <Col sm="12" className="mt-2">
                                        <label>Message Description</label>
                                        <FocusOneTextBox messageDescription={item.message_description} selectLang={item.language_id} changeDescription={this.handleDescription}/>
                                    </Col>
                                    <Col sm="12" className="mt-2">
                                        <label>Message Document</label>
                                        <EditorComponent changeContent={this.updateContent} messageDocument={item.message_document} selectLang={item.language_id} />
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                        </TabContent>
                    </div>
                }
            </React.Fragment>
            );
    }
}

export default connect(
    (state: ApplicationState) => state.focusonrMessgaeTabs,
    FocusOneMessageTabs.actionCreators
)(FocusOneMessageEditTabs);
