import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import SelectLanguage from './FocusOneLanguage';
import * as Models from '../models/Models'
import SmartSearct from './SmartSeacrh'
import { version } from 'react-dom';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, isBack: boolean, lang: string, version: string }> {

    language: Models.FocusOneLanguage | undefined

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            isBack: false,
            lang: '',
            version: ''
        };

        var _LANGUAGE = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');
        if (_LANGUAGE == null) {

            var setlang: Models.focusoneLanguage = {
                language_id: "EN",
                message_language: "English"
            }

            localStorage.setItem('FOCUSONEMESSAGE_LANGUAGE', JSON.stringify(setlang))           
        } 
    }

    componentDidMount() {
        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {                 
                }
            )
    }

    handleChangeLanguge(value: any) {
        if (value != null && value != "null") {
            this.language = JSON.parse(value);

            var setlang: Models.focusoneLanguage = {
                language_id: this.language!.language_id,
                message_language: this.language!.message_language
            }

            localStorage.setItem('FOCUSONEMESSAGE_LANGUAGE', JSON.stringify(setlang));
            window.location.reload();
        }        
    }

    public render() {       
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow bg-dark" light>
                    <Container fluid>
                        <NavbarBrand tag={Link} to="/" className="text-light">F1 SYSTEM MESSAGE<span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V {this.state.version}</span></NavbarBrand> 
                        <NavbarToggler onClick={this.toggle} className="mr-2" />    
                        <SmartSearct />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>          
                            <ul className="navbar-nav flex-grow">    
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/sign_in">Sign in</NavLink>
                                </NavItem>
                                <NavItem>
                                    <SelectLanguage selectLanguage={this.handleChangeLanguge.bind(this)} />
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}


                                    //<NavLink className="text-light d-inline-block" >TH</NavLink>
                                    //<span className="text-light d-inline-block">|</span>
                                    //<NavLink className="text-light d-inline-block" >EN</NavLink>