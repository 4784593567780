import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import FocusOneMessage from './components/FocusOneMessage';
import SignIn from './components/SignIn';
import FocusOneMessageCreate from './components/FocusOneMessageCreate';
import FocusOneLog from './components/FocusOnelog';

import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />       
        <Route path='/message/:messageId' component={FocusOneMessage} />
        <Route path='/messages' component={FocusOneMessageCreate} />
        <Route path='/sign_in' component={SignIn} />
        <Route path='/log' component={FocusOneLog} />
    </Layout>
);