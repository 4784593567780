import * as React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneLanguageData from '../store/FocusOneLanguage';

type FocusOneLanguageProps =
    FocusOneLanguageData.FocusOneLanguageState
    & typeof FocusOneLanguageData.actionCreators;

class FocusOneLanguage extends React.Component<any, FocusOneLanguageProps> {

    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.requestFocusOneLanguage("200", "GET");
    }

    handleChange(e: any) {   
        this.props.selectLanguage(e.target.value);
    }

    render() {
        return (
            <FormGroup row>
                <Col md={12}>
                    <select className="form-control" onChange={this.handleChange}>
                        <option value="null">Choose your language</option>
                        {this.props.data.map((item: FocusOneLanguageData.FocusOneLanguage) =>
                            <option key={item.language_id} value={JSON.stringify(item)} >{item.message_language}</option>
                        )}
                    </select>
                </Col>
            </FormGroup>
        );
    }

}

export default connect(
    (state: ApplicationState) => state.focusoneLanguage,
    FocusOneLanguageData.actionCreators
)(FocusOneLanguage);