import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneCategoriesData from '../store/FocusOneCategories';
import * as Models from '../models/Models'
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import ModalCreateCategory from './CreateCategoryModal';
import ModalEditCategory from './UpdateCategoryModal';
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneCategoryProps =
    FocusOneCategoriesData.FocusOneCategoryState
    & typeof FocusOneCategoriesData.actionCreators;


class FocusOneCategories extends React.Component<any, {
    createCategorymodal: boolean,
    editCategorymodal: boolean,
    categorys: string,
    alertStatusCategory: boolean
}, FocusOneCategoryProps> {

    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.createCategorymodals = this.createCategorymodals.bind(this);
        this.editCategorymodals = this.editCategorymodals.bind(this);

        this.state = {
            createCategorymodal: false,
            editCategorymodal: false,
            categorys: '{"id": "null", "message_category": "null"}',
            alertStatusCategory: false
        }
    }

    createCategorymodals(state: any) {
        this.setState({
            createCategorymodal: !this.state.createCategorymodal
        });
    }

    editCategorymodals(state: any) {
        this.setState({
            editCategorymodal: !this.state.editCategorymodal
        });
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestFocusOneCategory(LANG, "GET", null, null);
    }

    handleChangeCategory(e: any) {

        this.setState({
            categorys: e.target.value
        });

        this.props.selectCategory(e.target.value);
    }

    onCancelCate() {
        this.setState({
            alertStatusCategory: false
        });
    }

    deleteConfirmCate() {
        var modecate: Models.ChangeCategory = JSON.parse(this.state.categorys);
        if (modecate.id != 'null') {

            this.props.requestFocusOneCategory("EN", "DELETE", modecate.id, null);
        }

        this.setState({
            alertStatusCategory: false
        });
    }

    handleDeleteCate(e: any) {
        var modecate: Models.ChangeCategory = JSON.parse(this.state.categorys);

        if (modecate.id != 'null') {
            this.setState({
                alertStatusCategory: true
            });
        }
    }

    render() {
        return (
            <div className="from-group">

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="warning"
                    title="Are you sure?"
                    onConfirm={this.deleteConfirmCate.bind(this)}
                    onCancel={this.onCancelCate.bind(this)}
                    cancelBtnText="No"
                    cancelBtnBsStyle="light"
                    focusCancelBtn
                    show={this.state.alertStatusCategory}
                >
                    You want to delete
                </SweetAlert>

                <div className="row">
                    <div className="col-md-9">
                        <select className="form-control" onChange={this.handleChangeCategory}>
                            <option value='{"id":"null", "message_category": "null"}'>Choose your category</option>
                            {this.props.category.map((item: FocusOneCategoriesData.FocusOneCategory, index: any) =>
                                <option key={index} value={JSON.stringify(item)}>{item.message_category}</option>
                             )}                        
                        </select>                       
                    </div>

                    <div className="col-md">
                        <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.editCategorymodals}>
                            <ModalEditCategory loginmodal={this.state.editCategorymodal} loginmodals={this.editCategorymodals} categorydata={this.state.categorys} />
                            <FaPencilAlt />
                        </div>
                        <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
                        <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.createCategorymodals}>
                            <ModalCreateCategory loginmodal={this.state.createCategorymodal} loginmodals={this.createCategorymodals} />
                            <FaPlus />
                        </div>
                        <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
                        <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.handleDeleteCate.bind(this)}>
                            <FaTrashAlt />
                        </div>
                    </div>
                   
                </div>
            </div>
            );
    }

}

export default connect(
    (state: ApplicationState) => state.focusOneCategories,
    FocusOneCategoriesData.actionCreators
)(FocusOneCategories);