import * as React from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import * as FocusOneLanguage from '../store/FocusOneLanguage';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as Models from '../models/Models';
import { Spinner } from 'reactstrap';
import EditorComponent from './EditorComponent';
import FocusOneTextBox from './FocusOneTextBox';

type FocusOneLanguageProps =
    FocusOneLanguage.FocusOneLanguageState
    & typeof FocusOneLanguage.actionCreators;


class FocusOneMessageCreateTabs extends React.PureComponent<any, {
    activeTab: number,
    setActiveTab: number,
    messageList: Models.MessageTabsEdit[],
    messageDesc: Models.MessageTabsEditDescription[]
}, FocusOneLanguageProps> {

    langmodel: Models.focusoneLanguage | undefined;
    message: Models.MessageTabsEdit[] | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            activeTab: 1,
            setActiveTab: 1,
            messageList: [],
            messageDesc: []
        }

        this.updateContent = this.updateContent.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
    }

    public toggle(tab: number) {

        if (this.state.activeTab != tab) {
            this.setState({
                setActiveTab: tab,
                activeTab: tab
            });
        }
    }

    public updateContent(content: any, lang: any) {

        if (this.state.messageList.length > 0) {

            var rawMessage = this.state.messageList;
            var index = rawMessage.findIndex(i => i.language_id == lang);

            if (index > -1) {

                rawMessage[index].message_document = content
                this.setState({
                    messageList: rawMessage
                })

            } else {
                var mapping: Models.MessageTabsEdit = {
                    language_id: lang,
                    message_document: content
                };

                var messageMepping = this.state.messageList;
                messageMepping.push(mapping);

                this.setState({
                    messageList: messageMepping
                })
            }

        } else {
            var mapping: Models.MessageTabsEdit = {
                language_id: lang,
                message_document: content
            };

            var messageMepping = this.state.messageList;
            messageMepping.push(mapping);

            this.setState({
                messageList: messageMepping
            })
        }

        this.props.messageContent(this.state.messageList);
    }


    handleDescription(desc: any, lang: any) {

        if (this.state.messageDesc.length > 0) {

            var rawDesc = this.state.messageDesc;
            var index = rawDesc.findIndex(i => i.language_id == lang);

            if (index > -1) {
                rawDesc[index].message_description = desc;
                this.setState({
                    messageDesc: rawDesc
                });
            } else {
                var mepping: Models.MessageTabsEditDescription = {
                    language_id: lang,
                    message_description: desc
                }

                var descMepping = this.state.messageDesc;
                descMepping.push(mepping);

                this.setState({
                    messageDesc: descMepping
                });
            }

        } else {
            var mepping: Models.MessageTabsEditDescription = {
                language_id: lang,
                message_description: desc
            }

            var descMepping = this.state.messageDesc;
            descMepping.push(mepping);

            this.setState({
                messageDesc: descMepping
            });
        }

        this.props.messageDesc(this.state.messageDesc);
    }

    componentDidMount() {

        this.props.requestFocusOneLanguage("200", "GET");
    }

    render() {

        return (
            <React.Fragment>
                {this.props.isLoading &&
                    <div>
                    <Spinner key={'_SPN_CREATE_MESSAGE'} color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />
                    </div>
                }

                {!this.props.isLoading &&
                    <div>
                    <Nav tabs key={'_NAV_CREATE_MESSAGE'}>
                        {this.props.data.map((item: FocusOneLanguage.FocusOneLanguage, index: any) =>
                            <NavItem key={item.language_id}>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === index + 1 })}
                                        onClick={() => { this.toggle(index + 1); }}
                                        style={{ cursor: "pointer" }}>
                                        {item.language_id}
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.data.map((item: FocusOneLanguage.FocusOneLanguage, index: any) =>
                            <TabPane key={ index + 1} tabId={index + 1} className="mt-2">
                                    <Row>
                                        <Col sm="12" className="mt-2">
                                            <label>Message Description</label>
                                            <FocusOneTextBox messageDescription={''} selectLang={item.language_id} changeDescription={this.handleDescription} />
                                        </Col>
                                        <Col sm="12" className="mt-2">
                                            <label>Message Document</label>
                                            <EditorComponent changeContent={this.updateContent} messageDocument={''} selectLang={item.language_id} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            )}
                        </TabContent>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.focusoneLanguage,
    FocusOneLanguage.actionCreators
)(FocusOneMessageCreateTabs);
