import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { error } from 'console';

export interface FocusOneTypeState {
    isLoading: boolean;
    language?: string;
    data: FocusOneType[]
    method: string,
    responseCode: number,
    errormessage?: string
}

export interface FocusOneType {
    id: string,
    language_id: string,
    message_type: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface CreateFocusOneType {
    id: string,
    language_id: string,
    message_type: string
}

export interface ResponseMessage {
    message: string
}

interface RequestFocusOneTypeAction {
    type: 'REQUEST_TYPES';
    language: string;
    method: string;
}

interface ReceiveFocusOneTypeAction {
    type: 'RECEIVE_TYPES';
    language: string;
    data: FocusOneType[];
    method: string;
    responseCode: number,
    errormessage?: string
}

type KnownAction = RequestFocusOneTypeAction | ReceiveFocusOneTypeAction;

export const actionCreators = {
    requestFocusOneType: (language: string, method: string, id: string | null, body: CreateFocusOneType | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        const appState = getState();
        if (appState && appState.focusOneType && language !== appState.focusOneType.language && method == "GET") {
            fetch(`/v1/types`)
                .then(response => response.json() as Promise<FocusOneType[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: data, method: method, responseCode: 204 });
                });

            dispatch({ type: 'REQUEST_TYPES', language: language, method: method });

        } else if (appState && appState.focusOneType && method == "POST") {
            fetch(`/v1/type`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                    }

                    return response.json() as Promise<FocusOneType[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: data, method: method, responseCode: 200 });
                })
                .catch((error) => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: error, method: method, responseCode: 400, errormessage: error });
                });

            dispatch({ type: 'REQUEST_TYPES', language: language, method: method });

        } else if (appState && appState.focusOneType && method == "PUT") {

            fetch(`/v1/type/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                    }

                    return response.json() as Promise<FocusOneType[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: data, method: method, responseCode: 200 });
                })
                .catch((error) => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: error, method: method, responseCode: 400, errormessage: error });
                });

            dispatch({ type: 'REQUEST_TYPES', language: language, method: method });

        } else if (appState && appState.focusOneType && method == "DELETE") {

            fetch(`/v1/types/${id}`, {
                method: 'DELETE'
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                    }

                    return response.json() as Promise<FocusOneType[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: data, method: method, responseCode: 200 });
                })
                .catch((error) => {
                    dispatch({ type: 'RECEIVE_TYPES', language: language, data: error, method: method, responseCode: 400, errormessage: error });
                });

            dispatch({ type: 'REQUEST_TYPES', language: language, method: method });
        }
    }
}

const unloadedState: FocusOneTypeState = { data: [], isLoading: false, method: "GET", responseCode: 100 };

export const reducer: Reducer<FocusOneTypeState> = (state: FocusOneTypeState | undefined, incomingAction: Action): FocusOneTypeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_TYPES':
            return {
                language: action.language,
                data: state.data,
                isLoading: true,
                method: action.method,
                responseCode: state.responseCode
            };
        case 'RECEIVE_TYPES':
            if (action.language === state.language) {
                return {
                    language: action.language,
                    data: action.data,
                    isLoading: false,
                    method: action.method,
                    responseCode: action.responseCode
                };
            }
            break;
    }

    return state;
};