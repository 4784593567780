import * as React from 'react';
import '@sonarinno/jodit';
import '@sonarinno/jodit/build/jodit.min.css';
import JoditEditor from "@sonarinno/jodit-react";

class EditorComponent extends React.Component<any, {content: string}> {

    constructor(props: any) {
        super(props);

        this.state = {
            content: '',
        }

        this.updateContent = this.updateContent.bind(this);
    }

    updateContent(value: any) {

        this.setState({ content: value });
        this.props.changeContent(value, this.props.selectLang);
    }

    render() {
        return <JoditEditor
            value={this.props.messageDocument}
            onChange={this.updateContent}
        />
    }
}

export default EditorComponent