import * as React from 'react'
import SelectCategory from './FocusOneCategories';
import SelecType from './FocusOneTypes';
import Language from './FocusOneLanguage';
import ModalCreateCategory from './CreateCategoryModal';
import ModalCreateType from './CreateTypeModal';
import ModalCreateLanguage from './CreateLanguageModal';
import ModalEditCategory from './UpdateCategoryModal';
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import ModalEditType from "./UpdateTypeModal";
import ModalEditLanguage from "./UpdateLanguageModal";
import EditorComponent from './EditorComponent';
import * as Models from '../models/Models'
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneMessage from '../store/FocusOneMessage';
import * as FocusOneMessageConfiguration from '../store/FocusOneMessageConfiguarion';
import FocusOneMessageCreateTabs from './FocusOneMessageCreateTabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from "react-router";
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneMessageProps =
    FocusOneMessageConfiguration.FocusOneMessageConfigurationState
    & typeof FocusOneMessageConfiguration.actionCreators;

class FocusOneMessageCreate extends React.Component<any, {
    isOpen: boolean,
    createCategorymodal: boolean,
    createTypemodal: boolean,
    createLanguage: boolean,
    editCategorymodal: boolean,
    editTypemodal: boolean,
    editLanguage: boolean,
    lang: string,
    category: string,
    typeSelect: string,
    content: Models.MessageTabsEdit[],
    messageBody: Models.FocusOneMessages[],
    messageDesc: Models.MessageTabsEditDescription[],
    reqBody: Models.RequestMessage,
    messageCode: string,
    list: Models.ListMessage[],
    messageRaw: string,
    alertStatusType: boolean,
    alertStatusCategory: boolean
}, FocusOneMessageProps> {

    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            createCategorymodal: false,
            createTypemodal: false,
            createLanguage: false,
            editCategorymodal: false,
            editTypemodal: false,
            editLanguage: false,
            lang: '{"id": "null", "message_language": "null"}',
            category: '{"id": "null", "message_category": "null"}',
            typeSelect: '{"id": "null", "message_type": "null"}',
            content: [],
            messageBody: [],
            messageDesc: [],
            reqBody: { message_category_id: "", message_code: "", message_id: "", message_link: "", message_list: [], message_raw: "", message_type_id: "", user_id: "" },
            messageCode: '',
            list: [],
            messageRaw: '',
            alertStatusCategory: false,
            alertStatusType: false
        };

        this.createCategorymodals = this.createCategorymodals.bind(this);
        this.editCategorymodals = this.editCategorymodals.bind(this);
        this.editTypemodals = this.editTypemodals.bind(this);
        this.editLanguagemodals = this.editLanguagemodals.bind(this);
        this.createTypeModals = this.createTypeModals.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeLanguge = this.handleChangeLanguge.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.createLanguagemodals = this.createLanguagemodals.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.handleSubmitMessage = this.handleSubmitMessage.bind(this);
        this.getMessageDesc = this.getMessageDesc.bind(this);

    }

    updateContent(body: Models.MessageTabsEdit[]) {
        this.setState({
            content: body
        });
    }

    getMessageDesc(body: Models.MessageTabsEditDescription[]) {

        this.setState({
            messageDesc: body
        });
    }

    createCategorymodals(state: any) {
        this.setState({
            createCategorymodal: !this.state.createCategorymodal
        });
    }

    editCategorymodals(state: any) {
        this.setState({
            editCategorymodal: !this.state.editCategorymodal
        });
    }

    public handleChangeCategory(category: any) {
        this.setState({
            category: category
        });
    }

    createLanguagemodals(state: any) {
        this.setState({
            createLanguage: !this.state.createLanguage
        });
    }

    editTypemodals(state: any) {
        this.setState({
            editTypemodal: !this.state.editTypemodal
        });
    }

    editLanguagemodals(state: any) {
        this.setState({
            editLanguage: !this.state.editLanguage
        });
    }

    createTypeModals(state: any) {
        this.setState({
            createTypemodal: !this.state.createTypemodal
        });
    }

    public handleChangeType(type: any | undefined) {
        this.setState({
            typeSelect: type
        });
    }

    public handleChangeLanguge(lang: any) {
        this.setState({
            lang: lang
        });
    }

    handleSubmitMessage(e: any) {
        var { messageCode, category, typeSelect, content, messageDesc, messageRaw } = this.state

        toast.dismiss();

        if (this.validateData()) {
            return;
        }

        var requestdata: Models.RequestMessage;
        var cate: Models.ChangeCategory = JSON.parse(category);
        var types: Models.ChangeTypes = JSON.parse(typeSelect);
        var messageList: Models.ListMessage;

        for (var i = 0; i < messageDesc.length; i++) {

            if (content.length > 0) {

                for (var j = 0; j < content.length; j++) {

                    if (content[j].language_id === messageDesc[i].language_id) {

                        messageList = {
                            language_id: messageDesc[i].language_id,
                            message_description: messageDesc[i].message_description,
                            message_document: content[j].message_document
                        }

                        var _mlist = this.state.list;
                        _mlist.push(messageList);
                    }

                }
            } else {

                messageList = {
                    language_id: messageDesc[i].language_id,
                    message_description: messageDesc[i].message_description,
                    message_document: ""
                }

                var _mlist = this.state.list;
                _mlist.push(messageList);
            }
           
        }

        requestdata = {
            message_category_id: cate.id,
            message_link: "",
            message_type_id: types.id,
            message_code: messageCode,
            message_id: "",
            message_raw: messageRaw,
            user_id: "",
            message_list: this.state.list
        }

        var req = this.state.reqBody;
        req = requestdata;

        this.setState({
            reqBody: requestdata
        });

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }
                
        this.props.requestFocusOneMessageConfig("", "", LANG, !this.props.isLoading, "POST", "", req);
       
    }   

    validateData() {
        var { messageCode, category, typeSelect, messageDesc } = this.state
        var status = ""

        if (messageCode == "") {

            this.errorToaster("Please Enter Message Code");

            status += "Message code";
        }

        if (messageDesc.length == 0) {
            this.errorToaster("Please Enter Message Description");

            status += "Message Description";
        }

        var cate: Models.ChangeCategory = JSON.parse(category);
        var types: Models.ChangeTypes = JSON.parse(typeSelect);

        if (cate.id == "null") {
            this.errorToaster("Please Enter Change Category");

            status += "Change Category";
        }

        if (types.id == "null") {
            this.errorToaster("Please Enter Change Type");

            status += "Change Type";
        }

        if (status != "") {
            return true
        } else {
            return false
        }
        
    }

    errorToaster(val: string) {
        toast.error(val, {
            position: "bottom-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handleMessageCode(e: any) {
        this.setState({
            messageCode: e.target.value
        });
    }

    handleMessageRaw(e: any) {
        this.setState({
            messageRaw: e.target.value
        });
    }

    render() {

        //console.log(this.props.isLoading);
        //console.log(this.props.method);

        return (
            <React.Fragment>
                {this.props.isLoading &&
                    < div>
                        <Redirect to="/" />
                    </div>
                }

                {!this.props.isLoading &&
                    this.CreateElement()
                }
            </React.Fragment>
            );
    }

    private CreateElement() {
        return (
            <React.Fragment>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h3>Create Message</h3>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    Message Code
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <input type="text" className="form-control" onChange={this.handleMessageCode.bind(this)} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    Message Raw
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <input type="text" className="form-control" onChange={this.handleMessageRaw.bind(this)} required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md">
                                            Category
                                        </div>
                                        <div className="col-md-8">
                                            <SelectCategory selectCategory={this.handleChangeCategory} />
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md">
                                            Type
                                        </div>
                                        <div className="col-md-8">
                                            <SelecType selectTypes={this.handleChangeType} />
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-right">
                                <button onClick={this.handleSubmitMessage} className="btn btn-success">Save</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <FocusOneMessageCreateTabs messageContent={this.updateContent} messageDesc={this.getMessageDesc} />
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </React.Fragment>
            );
    }
}

//export default FocusOneMessageCreate

export default connect(
    (state: ApplicationState) => state.focusontMessageConfig,
    FocusOneMessageConfiguration.actionCreators
)(FocusOneMessageCreate);

    //< div className = "col-md-12" >
    //    <div className="row">
    //        <div className="col-md-8">
    //            <div className="row">
    //                <div className="col-md">
    //                    Language
    //                                    </div>
    //                <div className="col-md-8">
    //                    <Language selectLanguage={this.handleChangeLanguge} />
    //                </div>
    //            </div>
    //        </div>
    //        <div className="col-md">
    //            <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.editLanguagemodals}>
    //                <ModalEditLanguage loginmodal={this.state.editLanguage} loginmodals={this.editLanguagemodals} languagedata={this.state.lang} />
    //                <FaPencilAlt />
    //            </div>
    //            <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
    //            <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.createLanguagemodals}>
    //                <ModalCreateLanguage languagemodal={this.state.createLanguage} languagemodals={this.createLanguagemodals} />
    //                <FaPlus />
    //            </div>
    //        </div>
    //    </div>
    //                    </div >

//< div>
//    <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />
//</div>

//<div className="col-md">
//    <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.editCategorymodals}>
//        <ModalEditCategory loginmodal={this.state.editCategorymodal} loginmodals={this.editCategorymodals} categorydata={this.state.category} />
//        <FaPencilAlt />
//    </div>
//    <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
//    <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.createCategorymodals}>
//        <ModalCreateCategory loginmodal={this.state.createCategorymodal} loginmodals={this.createCategorymodals} />
//        <FaPlus />
//    </div>
//    <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
//    <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.handleDeleteCate.bind(this)}>
//        <FaTrashAlt />
//    </div>
//</div>

//<div className="col-md">
//    <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.editTypemodals}>
//        <ModalEditType loginmodal={this.state.editTypemodal} loginmodals={this.editTypemodals} typedata={this.state.typeSelect} />
//        <FaPencilAlt />
//    </div>
//    <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
//    <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.createTypeModals}>
//        <ModalCreateType typemodal={this.state.createTypemodal} typemodals={this.createTypeModals} />
//        <FaPlus />
//    </div>
//    <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
//    <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.handleDeleteType.bind(this)}>
//        <FaTrashAlt />
//    </div>
//</div>