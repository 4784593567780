import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from './NavMenu';
import SideBarMenu from './SideBarMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu />        
        <Container fluid={true}>
            <Row>
                <Col md="2" style={{ paddingLeft: "0" }}>
                    <SideBarMenu />
                </Col>
                <Col md="10" lg="10" className="px-4">
                    <main role="main" style={{ minHeight: "92.5vh" }}>
                        {props.children}
                    </main>                    
                </Col>
            </Row>         
        </Container>
    </React.Fragment>
);
