import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { error } from 'console';

export interface FocusOneLanguageState {
    isLoading: boolean;
    data: FocusOneLanguage[];
    statusCode?: string;
    method: string,
    responseCode: number,
    errormessage?: string
}

export interface FocusOneLanguage {
    //id: string,
    language_id: string,
    message_language: string
    //created_on: string,
    //created_by: string,
    //updated_on: string,
    //updated_by: string
}

export interface ResponseMessage {
    message: string
}


export interface CreateFocusOneLanguage {
    language_id: string,
    message_language: string
}

interface RequestFocusOneLanguageAction {
    type: 'REQUEST_LANGUAGE';
    statusCode: string;
    method: string;
}

interface ReceiveFocusOneLanguageAction {
    type: 'RECEIVE_LANGUAGE';
    data: FocusOneLanguage[];
    statusCode: string;
    method: string;
    responseCode: number,
    errormessage?: string
}

type KnownAction = RequestFocusOneLanguageAction | ReceiveFocusOneLanguageAction;

export const actionCreators = {
    requestFocusOneLanguage: (statusCode: string, method: string, id: string | null, body: CreateFocusOneLanguage | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.focusoneLanguage && statusCode !== appState.focusoneLanguage.statusCode && method == "GET") {

            fetch(`/v1/language`)
                .then(response => response.json() as Promise<FocusOneLanguage[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_LANGUAGE', statusCode: statusCode, data: data, method: method, responseCode: 204 });
                });

            dispatch({ type: 'REQUEST_LANGUAGE', method: method, statusCode: statusCode });

        } else if (appState && appState.focusoneLanguage && method == "POST") {

            fetch(`/v1/language`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                    }

                    return response.json() as Promise<FocusOneLanguage[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_LANGUAGE', statusCode: statusCode, data: data, method: method, responseCode: 200 });
                })
                .catch((error) => {

                    dispatch({ type: 'RECEIVE_LANGUAGE', statusCode: statusCode, data: error, method: method, responseCode: 400, errormessage: error });

                });;

            dispatch({ type: 'REQUEST_LANGUAGE', method: method, statusCode: statusCode });

        } else if (appState && appState.focusoneLanguage && method == "PUT") {
            
            fetch(`/v1/language/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                    }

                    return response.json() as Promise<FocusOneLanguage[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_LANGUAGE', statusCode: statusCode, data: data, method: method, responseCode: 200 });
                })
                .catch((error) => {
                    dispatch({ type: 'RECEIVE_LANGUAGE', statusCode: statusCode, data: error, method: method, responseCode: 400, errormessage: error });
                });

            dispatch({ type: 'REQUEST_LANGUAGE', method: method, statusCode: statusCode });

        }
    }
}

const unloadedState: FocusOneLanguageState = { data: [], isLoading: false, method: "GET", responseCode: 100 };

export const reducer: Reducer<FocusOneLanguageState> = (state: FocusOneLanguageState | undefined, incomingAction: Action): FocusOneLanguageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LANGUAGE':
            return {
                statusCode: action.statusCode,
                data: state.data,
                isLoading: true,
                method: action.method,
                responseCode: state.responseCode
            };
        case 'RECEIVE_LANGUAGE':
            if (action.statusCode === state.statusCode) {
                return {
                    statusCode: action.statusCode,
                    data: action.data,
                    isLoading: false,
                    method: action.method,
                    responseCode: action.responseCode
                };
            }
            break;
    }

    return state;
};