import * as React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneTypeData from '../store/FocusOneType';
import * as Models from '../models/Models'
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import ModalEditType from "./UpdateTypeModal";
import ModalCreateType from './CreateTypeModal';
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneTypeProps =
    FocusOneTypeData.FocusOneTypeState
    & typeof FocusOneTypeData.actionCreators;

class FocusOneTypes extends React.Component<any, {
    typeSelect: string,
    editTypemodal: boolean,
    createTypemodal: boolean,
    alertStatusType: boolean
}, FocusOneTypeProps> {

    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.editTypemodals = this.editTypemodals.bind(this);
        this.createTypeModals = this.createTypeModals.bind(this);

        this.state = {
            alertStatusType: false,
            createTypemodal: false,
            editTypemodal: false,
            typeSelect: '{"id": "null", "message_type": "null"}'
        }
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.requestFocusOneType("EN", "GET");
    }

    createTypeModals(state: any) {
        this.setState({
            createTypemodal: !this.state.createTypemodal
        });
    }

    handleChange(e: any) {

        this.setState({
            typeSelect: e.target.value
        });

        this.props.selectTypes(e.target.value);
    }

    editTypemodals(state: any) {
        this.setState({
            editTypemodal: !this.state.editTypemodal
        });
    }

    onCancelType() {
        this.setState({
            alertStatusType: false
        });
    }

    handleDeleteType(e: any) {

        var modelType: Models.ChangeTypes = JSON.parse(this.state.typeSelect);

        if (modelType.id != 'null') {
            this.setState({
                alertStatusType: true
            });
        }
    }

    deleteConfirmType() {

        var modelType: Models.ChangeTypes = JSON.parse(this.state.typeSelect);

        if (modelType.id != 'null') {

            this.props.requestFocusOneType("EN", "DELETE", modelType.id, null);
            //fetch(`/v1/types/${modelType.id}`, {
            //    method: 'DELETE'
            //})
            //    .then(response => response.json())
            //    .then(data => {

            //    });
        }

        this.setState({
            alertStatusType: false
        });

    }

    render() {
        return (
            <div className="form-group">

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="warning"
                    title="Are you sure?"
                    onConfirm={this.deleteConfirmType.bind(this)}
                    onCancel={this.onCancelType.bind(this)}
                    cancelBtnText="No"
                    cancelBtnBsStyle="light"
                    focusCancelBtn
                    show={this.state.alertStatusType}
                >
                    You want to delete
                </SweetAlert>

                <div className="row">
                    <div className="col-md-9">
                        <select className="form-control" onChange={this.handleChange}>
                            <option value="null">Choose your type</option>
                            {this.props.data.map((item: FocusOneTypeData.FocusOneType) =>
                                <option key={item.message_type} value={JSON.stringify(item)}>{item.message_type}</option>
                             )}
                        </select>
                    </div>

                    <div className="col-md">
                        <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.editTypemodals}>
                            <ModalEditType loginmodal={this.state.editTypemodal} loginmodals={this.editTypemodals} typedata={this.state.typeSelect} />
                            <FaPencilAlt />
                        </div>
                        <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
                        <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.createTypeModals}>
                            <ModalCreateType typemodal={this.state.createTypemodal} typemodals={this.createTypeModals} />
                            <FaPlus />
                        </div>
                        <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
                        <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.handleDeleteType.bind(this)}>
                            <FaTrashAlt />
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export default connect(
    (state: ApplicationState) => state.focusOneType,
    FocusOneTypeData.actionCreators
)(FocusOneTypes);