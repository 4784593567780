import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Language from './FocusOneLanguage';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneCategoriesData from '../store/FocusOneCategories';
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneCategoryProps =
    FocusOneCategoriesData.FocusOneCategoryState
    & typeof FocusOneCategoriesData.actionCreators;

export interface FocusOneLanguage {
    id: string,
    language_id: string,
    message_language: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface focusoneLanguage {
    language_shot: string,
    language: string
}

class CreateCategoryModal extends React.Component<any, {
    lang: string,
    category: string,
    id: string,
    isHideAlert: boolean,
    isHideAlertSuccess: boolean
}, FocusOneCategoryProps> {

    languageModel: FocusOneLanguage | undefined;
    langmodel: focusoneLanguage | undefined;
    stateSuccessAlert: boolean | undefined;

    constructor(props: any) {
        super(props);

        this.handleCreateCategory = this.handleCreateCategory.bind(this);
        this.handleChangeLanguge = this.handleChangeLanguge.bind(this);
        this.handleInputCategory = this.handleInputCategory.bind(this);
        this.handleInputCategoryId = this.handleInputCategoryId.bind(this);
        this.onConfirm = this.onConfirm.bind(this);

        this.state = {
            lang: 'EN',
            category: '',
            id: '',
            isHideAlert: false,
            isHideAlertSuccess: false
        }

        this.stateSuccessAlert = false;
    }

    handleCreateCategory(e: any) {

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_shot;
        }

        var { id, lang, category } = this.state
        this.props.requestFocusOneCategory(LANG, "POST", "", { id: id, language_id: lang, message_category: category });        

        this.stateSuccessAlert = true;

        e.preventDefault();
    }

    handleChangeLanguge(lang: any) {

        if (lang != null) {
            this.languageModel = JSON.parse(lang);

            this.setState({
                lang: this.languageModel!.language_id
            })
        }
    }

    handleInputCategory(e: any) {
        this.setState({
            category: e.target.value
        });
    }

    handleInputCategoryId(e: any) {
        this.setState({
            id: e.target.value
        });
    }

    onConfirm(response: any) {

        this.setState({
            isHideAlert: false,
            isHideAlertSuccess: false
        });
    }

    render() {

        if (!this.props.isLoading) {
            if (this.props.responseCode != undefined) {
                if (this.stateSuccessAlert != undefined && this.stateSuccessAlert) {

                    if (this.props.responseCode === 200 ) {

                        this.setState({ isHideAlertSuccess: true });
                        this.stateSuccessAlert = false;

                    } else if (this.props.responseCode == 400) {

                        this.setState({ isHideAlert: true });
                        this.stateSuccessAlert = false;

                    } 
                }
            }
        } 

        return (            
            <div onClick={this.props.loginmodals}>                
                <Modal isOpen={this.props.loginmodal} toggle={this.props.loginmodals} className={this.props.className}>
                    <ModalHeader toggle={this.props.loginmodals}>Create Category</ModalHeader>
                    <form onSubmit={this.handleCreateCategory}>
                    <ModalBody>
                        
                            <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                     <label>Category ID</label>
                                    <input type="text" className="form-control" onChange={this.handleInputCategoryId} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Category Name</label> 
                                    <input type="text" className="form-control" onChange={this.handleInputCategory} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Language</label>
                                    <Language selectLanguage={this.handleChangeLanguge} />
                                </div>
                            </div>
                        </div>
                    
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-info btn-block">Save</button>
                    </ModalFooter>
                    </form>
                </Modal>

                {!this.props.isLoading &&
                    <SweetAlert error title="Create Category Fail" show={this.state.isHideAlert} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                        <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />


                }
                {!this.props.isLoading &&
                    <SweetAlert success title="Create Category Success" show={this.state.isHideAlertSuccess} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                        <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />
                }

            </div>
            );
    }

    
}

export default connect(
    (state: ApplicationState) => 
            state.focusOneCategories,           
            FocusOneCategoriesData.actionCreators
)(CreateCategoryModal);

//export default CreateCategoryModal;