import * as React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneMessageConfigData from '../store/FocusOneMessageConfiguarion';
import '../style/Tables.css';
import { Spinner } from 'reactstrap';
import * as Models from '../models/Models';
import { FaTrashAlt } from "react-icons/fa";
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneMessageConfig =
    FocusOneMessageConfigData.FocusOneMessageConfigurationState
    & typeof FocusOneMessageConfigData.actionCreators;

class FocusOneMessageTable extends React.Component<any, { messageId: string, alertStatus: boolean},FocusOneMessageConfig> {

    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            messageId: '',
            alertStatus: false
        }
    }

    handleToMessage(message_code: string) {
        var link = `/message/${message_code}`
        //this.props.history.push(link);
        this.props.handlePage(link);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }  

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {

        const query = new URLSearchParams(window.location.search);
        let category = query.get('category');
        let types = query.get('types');
        
        if (category == null) {
            category = ""
        }

        if (types == null) {
            types = ""
        }

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestFocusOneMessageConfig(category, types, LANG, !this.props.isLoading, "GET", "");   
    }

    handleClickDelete(id: string) {
        this.setState({
            messageId: id,
            alertStatus: true
        });
    }

    deleteConfirm(e: any) {
        const query = new URLSearchParams(window.location.search);
        let category = query.get('category');
        let types = query.get('types');

        if (category == null) {
            category = ""
        }

        if (types == null) {
            types = ""
        }

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestFocusOneMessageConfig(category, types, LANG, !this.props.isLoading, "DELETE", this.state.messageId);

        this.setState({
            messageId: '',
            alertStatus: false
        });
    }

    onCancel() {
        this.setState({
            messageId: '',
            alertStatus: false
        });
    }

    render() {
        return (
            
                <div className="mt-4">
                    {!this.props.isLoading && this.reanderDataTables()}
                   
                    {this.props.isLoading &&
                        <div>
                            <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />
                        </div>
                    }

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="warning"
                    title="Are you sure?"
                    onConfirm={this.deleteConfirm.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    cancelBtnText="No"
                    cancelBtnBsStyle="light"
                    focusCancelBtn
                    show={this.state.alertStatus}
                >
                    You want to delete
                </SweetAlert>
                </div>
        );
    }

    private reanderDataTables() {
        return (
            <React.Fragment>
                <table className='table table-bordered' aria-labelledby="tabelLabel">
                    <thead>
                        <tr style={{ background: "hsl(31, 67%, 67%)", color: "#fff" }}>
                            <th>Message Code</th>
                            <th>Category</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>                        

                        {this.props.message.map((item: FocusOneMessageConfigData.FocusOneMessageConfig) =>

                            <tr key={item.message_code} className="table-tbody" >
                                <td onClick={() => this.handleToMessage(item.message_code)}>{item.message_code}</td>
                                <td onClick={() => this.handleToMessage(item.message_code)}>{item.message_category}</td>
                                <td onClick={() => this.handleToMessage(item.message_code)}>{item.message_type}</td>
                                <td onClick={() => this.handleToMessage(item.message_code)}>{(item.message_description != null) ? item.message_description : ""}</td>
                                <td>
                                    <div onClick={() => this.handleClickDelete(item.message_id)}>
                                        <FaTrashAlt />
                                    </div>
                                </td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default connect((state: ApplicationState) => state.focusontMessageConfig,
    FocusOneMessageConfigData.actionCreators
)(FocusOneMessageTable);