import * as React from 'react';
import SelectCategory from './FocusOneCategories';
import SelecType from './FocusOneTypes';
import Language from './FocusOneLanguage';
import ModalCreateCategory from './CreateCategoryModal';
import ModalCreateType from './CreateTypeModal';
import ModalCreateLanguage from './CreateLanguageModal';
import ModalEditCategory from './UpdateCategoryModal';
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import ModalEditType from "./UpdateTypeModal";
import ModalEditLanguage from "./UpdateLanguageModal";
import EditorComponent from './EditorComponent';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneMessage from '../store/FocusOneMessage';
import FocusOneMessageEditTabs from './FocusOneMessageEditTabs';
import * as Models from '../models/Models';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as FocusOneCategoriesData from '../store/FocusOneCategories';

type FocusOneMessageProps =
    FocusOneMessage.FocusOneMessageState
    & typeof FocusOneMessage.actionCreators
    & FocusOneCategoriesData.FocusOneCategoryState
    & typeof FocusOneCategoriesData.actionCreators
    & RouteComponentProps<{ messageId: string }>;

class FocusMessageEdit extends React.Component<any, {
    isOpen: boolean,
    createCategorymodal: boolean,
    createTypemodal: boolean,
    createLanguage: boolean,
    editCategorymodal: boolean,
    editTypemodal: boolean,
    editLanguage: boolean,
    lang: string,
    category: string,
    typeSelect: string,
    content: Models.MessageTabsEdit[],
    messageBody: Models.FocusOneMessages[],
    messageDesc: Models.MessageTabsEditDescription[],
    reqBody: Models.RequestMessage,
    list: Models.ListMessage[],
    messageCode: string,
    messageRaw: string,
    messageId: string,
    alertStatusCategory: boolean,
    alertStatusType: boolean,
}, FocusOneMessageProps> {
    
    items: Models.FocusOneMessages | undefined
    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.props.messageList.map((item: Models.FocusOneMessages) => {
            this.items = item;
        });      

        this.state = {
            isOpen: false,
            createCategorymodal: false,
            createTypemodal: false,
            createLanguage: false,
            editCategorymodal: false,
            editTypemodal: false,
            editLanguage: false,
            lang: '{"id": "null", "message_language": "null"}',
            category: '{"id": "null", "message_category": "null"}',
            typeSelect: '{"id": "null", "message_type": "null"}',
            content: [],
            messageBody: [],
            messageDesc: [],
            reqBody: { message_category_id: '', message_code: '', message_id: '', message_link: '', message_list: [], message_raw: '', message_type_id: '', user_id:'' },
            list: [],
            messageCode: this.props.messageId,
            messageRaw: this.props.messageList[0].message_raw,
            messageId: this.props.messageList[0].message_id,
            alertStatusCategory: false,
            alertStatusType: false
        };

        this.createCategorymodals = this.createCategorymodals.bind(this);
        this.editCategorymodals = this.editCategorymodals.bind(this);
        this.editTypemodals = this.editTypemodals.bind(this);
        this.editLanguagemodals = this.editLanguagemodals.bind(this);
        this.createTypeModals = this.createTypeModals.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeLanguge = this.handleChangeLanguge.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.createLanguagemodals = this.createLanguagemodals.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.handleSubmitMessage = this.handleSubmitMessage.bind(this);
        this.getMessageBody = this.getMessageBody.bind(this);
        this.getMessageDesc = this.getMessageDesc.bind(this);
    }

    createCategorymodals(state : any) {
        this.setState({
            createCategorymodal: !this.state.createCategorymodal
        });
    }

    editCategorymodals(state: any) {
        this.setState({
            editCategorymodal: !this.state.editCategorymodal
        });
    }

    createLanguagemodals(state: any) {
        this.setState({
            createLanguage: !this.state.createLanguage
        });
    }

    editTypemodals(state: any) {
        this.setState({
            editTypemodal: !this.state.editTypemodal
        });
    }

    editLanguagemodals(state: any) {
        this.setState({
            editLanguage: !this.state.editLanguage
        });
    }

    createTypeModals(state: any) {
        this.setState({
            createTypemodal: !this.state.createTypemodal
        });
    }

    updateContent(body: Models.MessageTabsEdit[]) {
        this.setState({
            content: body
        });
    }

    getMessageBody(body: Models.FocusOneMessages[]) {

        this.setState({
            messageBody: body
        });
    }

    getMessageDesc(body: Models.MessageTabsEditDescription[]) {

        this.setState({
            messageDesc: body
        });
    }

    public handleChangeCategory(category: any) {
        this.setState({
            category: category
        });
    }

    public handleChangeLanguge(lang: any) {
        this.setState({
            lang: lang
        });
    }

    public handleChangeType(type: any | undefined) {
        this.setState({
            typeSelect: type
        });
    }

    handleSubmitMessage(e: any) {

        if (this.state.messageBody != null) {

            this.setState({
                list: [],
            });            

            for (var i = 0; i < this.state.messageBody.length; i++) {

                var index_doc = this.state.content.findIndex(doc => doc.language_id === this.state.messageBody[i].language_id);
                var index_desc = this.state.messageDesc.findIndex(desc => desc.language_id === this.state.messageBody[i].language_id);
                var requestdata: Models.RequestMessage;
                var messageList: Models.ListMessage;

                if (index_doc > -1 && index_desc > -1) {                   

                    messageList = {
                        language_id: this.state.messageBody[i].language_id,
                        message_description: this.state.messageDesc[index_desc].message_description,
                        message_document: this.state.content[index_doc].message_document
                    }


                } else if (index_doc > -1 && index_desc <= -1) {                   

                    messageList = {
                        language_id: this.state.messageBody[i].language_id,
                        message_description: this.state.messageBody[i].message_description,
                        message_document: this.state.content[index_doc].message_document
                    }

                } else if (index_doc <= -1 && index_desc > -1) {

                    messageList = {
                        language_id: this.state.messageBody[i].language_id,
                        message_description: this.state.messageDesc[index_desc].message_description,
                        message_document: this.state.messageBody[i].message_document
                    }

                } else {

                    messageList = {
                        language_id: this.state.messageBody[i].language_id,
                        message_description: this.state.messageBody[i].message_description,
                        message_document: this.state.messageBody[i].message_document
                    }
                }


                this.state.list.push(messageList);

            }

            requestdata = {
                message_category_id: this.props.messageList[0].message_category_id,
                message_link: this.props.messageList[0].message_link,
                message_type_id: this.props.messageList[0].message_type_id,
                message_code: this.state.messageCode,
                message_id: this.state.messageId,
                message_raw: this.state.messageRaw,
                message_list: this.state.list,
                user_id: ""
            }

            console.log(requestdata);

            var req = this.state.reqBody
            req = requestdata

            this.setState({
                reqBody : {
                    message_category_id: this.props.messageList[0].message_category_id,
                    message_link: this.props.messageList[0].message_link,
                    message_type_id: this.props.messageList[0].message_type_id,
                    message_code: this.state.messageCode,
                    message_id: this.state.messageId,
                    message_raw: this.state.messageRaw,
                    message_list: this.state.list,
                    user_id: ""                   
                }
            });

            console.log(this.state.reqBody);

            //for (var r = 0; r < this.state.reqBody.length; r++) {
                var LANG = "EN"
                var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

                if (_LANG != null) {
                    this.langmodel = JSON.parse(_LANG);

                    LANG = this.langmodel!.language_id;
                }

            this.props.requestFocusOneMessage(this.props.messageId, "PUT", this.props.messageId, req, LANG);
            //}
        }
    }

    render() {
        return (
                this.contentBody()                  
            );
    }

    handleMessageCode(e: any) {
        this.setState({
            messageCode: e.target.value
        });
    }

    handleMessageRaw(e: any) {
        this.setState({
            messageRaw: e.target.value
        });
    }

    onCancelCate() {
        this.setState({
            alertStatusCategory: false
        });
    }

    deleteConfirmCate() {
        var modecate: Models.ChangeCategory = JSON.parse(this.state.category);
        if (modecate.id != 'null') {

            fetch(`/v1/category/${modecate.id}`, {
                method: 'DELETE'
            })
            .then(response => response.json())
            .then(data => {
                    
            });
        }

        this.setState({
            alertStatusCategory: false
        });        
    }

    handleDeleteCate(e: any) {
        var modecate: Models.ChangeCategory = JSON.parse(this.state.category);

        if (modecate.id != 'null') {
            this.setState({
                alertStatusCategory: true
            });
        }
    }

    onCancelType() {
        this.setState({
            alertStatusType: false
        });
    }

    handleDeleteType(e: any) {
        var modelType: Models.ChangeTypes = JSON.parse(this.state.typeSelect);

        if (modelType.id != 'null') {
            this.setState({
                alertStatusType: true
            });
        }
    }

    deleteConfirmType() {

        var modelType: Models.ChangeTypes = JSON.parse(this.state.typeSelect);
       
        if (modelType.id != 'null') {

            fetch(`/v1/types/${modelType.id}`, {
                method: 'DELETE'
            })
                .then(response => response.json())
                .then(data => {

                });
        }

        this.setState({
            alertStatusType: false
        });
        
    }

    public contentBody() {
        return (
            <React.Fragment>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="warning"
                    title="Are you sure?"
                    onConfirm={this.deleteConfirmCate.bind(this)}
                    onCancel={this.onCancelCate.bind(this)}
                    cancelBtnText="No"
                    cancelBtnBsStyle="light"
                    focusCancelBtn
                    show={this.state.alertStatusCategory}
                >
                    You want to delete
                </SweetAlert>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="warning"
                    title="Are you sure?"
                    onConfirm={this.deleteConfirmType.bind(this)}
                    onCancel={this.onCancelType.bind(this)}
                    cancelBtnText="No"
                    cancelBtnBsStyle="light"
                    focusCancelBtn
                    show={this.state.alertStatusType}
                >
                    You want to delete
                </SweetAlert>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    Message Code
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <input type="text" className="form-control" defaultValue={this.state.messageCode} onChange={this.handleMessageCode.bind(this)} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md">
                                    Message Raw
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <input type="text" className="form-control" defaultValue={this.state.messageRaw} onChange={this.handleMessageRaw.bind(this)} required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md">
                                            Category
                                        </div>
                                        <div className="col-md-8">
                                            <SelectCategory selectCategory={this.handleChangeCategory} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md">
                                            Type
                                       </div>
                                        <div className="col-md-8">
                                            <SelecType selectTypes={this.handleChangeType} />
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="text-right">
                                <button onClick={this.handleSubmitMessage} className="btn btn-success">Save</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <FocusOneMessageEditTabs messageCode={this.props.messageId} messageContent={this.updateContent} messageBody={this.getMessageBody} messageDesc={this.getMessageDesc} />
                        </div>
                    </div>                
                </div>
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.focusOneMessage, 
        FocusOneMessage.actionCreators        
)(FocusMessageEdit);
//export default FocusMessageEdit;
//<div className="col-md-12">
//    <EditorComponent changeContent={this.updateContent} messageDocument={this.state.content} />
//</div>
//<div className="row">
//    <br />
//    <SystemMessageTabComponent listData={this.items} />
//</div>

//<div className="col-md-12">
//    <div className="row">
//        <div className="col-md-8">
//            <div className="row">
//                <div className="col-md">
//                    Language
//                                    </div>
//                <div className="col-md-10">
//                    <Language selectLanguage={this.handleChangeLanguge} />
//                </div>
//            </div>
//        </div>
//        <div className="col-md">
//            <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.editLanguagemodals}>
//                <ModalEditLanguage loginmodal={this.state.editLanguage} loginmodals={this.editLanguagemodals} languagedata={this.state.lang} />
//                <FaPencilAlt />
//            </div>
//            <span style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "context-menu" }}>|</span>
//            <div className="d-inline-block" style={{ cursor: "pointer" }} onClick={this.createLanguagemodals}>
//                <ModalCreateLanguage languagemodal={this.state.createLanguage} languagemodals={this.createLanguagemodals} />
//                <FaPlus />
//            </div>
//        </div>
//    </div>
//</div>