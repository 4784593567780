import * as React from 'react';
import { connect } from 'react-redux';
import FocusOneMessageTable from './FocusOneMessageTable';


class Home extends React.PureComponent<any, {category: string , types: string}> {

    constructor(props: any) {
        super(props);

        this.state = {
            category: "",
            types: ""
        }

    }
       

    handlePage(val: any) {
        this.props.history.push(val);
    }

    render() {
        return (
            <FocusOneMessageTable handlePage={this.handlePage.bind(this)} />
            )
    }
}

export default Home

//const Home = () => ( 
    
//    <div>
    
//    </div>

//)

//export default connect()(Home);
//    <FocusOneMessageTable />