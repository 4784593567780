import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface FocusOneMessageState {
    isLoading: boolean;
    massageCode?: string;
    message: FocusOneMessage[],
    method: string
}

export interface FocusOneMessage {
    user_id: string,
    message_code: string,
    message_category_id: string,
    message_type_id: string,
    message_link: string,
    language_id: string,
    message_description: string,
    message_document: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface RequestMessage {
    message_category_id: string,
    message_type_id: string,
    message_link: string,
    language_id: string,
    message_description: string,
    message_document: string
}

export interface ResponseMessage {
    message: string
}

interface RequestFocusOneMessageAction {
    type: 'REQUEST_MESSAGE';
    massageCode: string;
    method: string;
}

interface ReceiveFocusOneMessageAction {
    type: 'RECEIVE_MESSAGE';
    massageCode: string;
    message: FocusOneMessage[];
    method: string;
}

type KnownAction = RequestFocusOneMessageAction | ReceiveFocusOneMessageAction;

export const actionCreators = {
    requestFocusOneMessage: (messageCode: string, method: string, id: string | null, body: RequestMessage | null, language: string| null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.focusOneMessage && messageCode !== appState.focusOneMessage.massageCode && method === "GET") {
            fetch(`v1/messages/${messageCode}?lang=${language}`)
                .then(response => response.json() as Promise<FocusOneMessage[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE', massageCode: messageCode, message: data, method: method });
                });

            dispatch({ type: 'REQUEST_MESSAGE', massageCode: messageCode, method: method });
        } else if (appState && appState.focusOneMessage && method === "PUT") {
            fetch(`v1/messages/${messageCode}?lang=${language}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<FocusOneMessage[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE', massageCode: messageCode, message: data, method: method });
                });

            dispatch({ type: 'REQUEST_MESSAGE', massageCode: messageCode, method: method });

        } else if (appState && appState.focusOneMessage && method === "POST") {
            debugger;
            fetch(`v1/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => 
                     response.json() as Promise<FocusOneMessage[]>
                )
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE', massageCode: '200', message: data, method: method });
                })

            dispatch({ type: 'REQUEST_MESSAGE', massageCode: messageCode, method: method });
        }
    }
}

const unloadedState: FocusOneMessageState = { message: [], isLoading: false, method: '' };

export const reducer: Reducer<FocusOneMessageState> = (state: FocusOneMessageState | undefined, incomingAction: Action): FocusOneMessageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MESSAGE':
            return {
                massageCode: action.massageCode,
                message: state.message,
                isLoading: true,
                method: state.method
            };
        case 'RECEIVE_MESSAGE':
            if (action.massageCode === state.massageCode) {
                return {
                    massageCode: action.massageCode,
                    message: action.message,
                    isLoading: false,
                    method: action.method
                };
            }
            break;
    }

    return state;
};