import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface FocusOneMessageTabsState {
    isLoading: boolean;
    massageCode?: string;
    message: FocusOneMessage[];
    listLang: FocusOneLanguage[];
    language?: string
}

export interface ResponseFocusOneMessage {
    languages: FocusOneLanguage[],
    systemMessages: FocusOneMessage[]
}

export interface FocusOneMessage {
    user_id: string,
    message_code: string,
    message_category_id: string,
    message_type_id: string,
    message_link: string,
    language_id: string,
    message_description: string,
    message_document: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface RequestMessage {
    user_id: string,
    message_id: string,
    message_code: string,
    message_category_id: string,
    message_type_id: string,
    message_link: string,
    message_raw: string,
    message_list: ListMessage[]
}

export interface FocusOneLanguage {
    language_id: string,
    message_language: string
}

export interface ListMessage {
    language_id: string,
    message_description: string,
    message_document: string
}

interface RequestFocusOneMessageTabsAction {
    type: 'REQUEST_MESSAGE_TABS';
    massageCode: string;
}

interface ReceiveFocusOneMessageTabsAction {
    type: 'RECEIVE_MESSAGE_TABS';
    massageCode: string;
    message: FocusOneMessage[];
    listLang: FocusOneLanguage[];
    language?: string
}

type KnownAction = RequestFocusOneMessageTabsAction | ReceiveFocusOneMessageTabsAction;

export const actionCreators = {
    requestFocusOneMessageLabs: (messageCode: string, method: string, id: string | null, body: RequestMessage | null, language: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.focusonrMessgaeTabs && messageCode !== appState.focusonrMessgaeTabs.massageCode && method === "GET") {
            fetch(`/v1/messages/${messageCode}/tabs`)
                .then(response => response.json() as Promise<ResponseFocusOneMessage>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE_TABS', massageCode: messageCode, message: data.systemMessages, listLang: data.languages });
                });

            dispatch({ type: 'REQUEST_MESSAGE_TABS', massageCode: messageCode });
        } else if (appState && appState.focusonrMessgaeTabs && method === "PUT") {
            fetch(`/v1/messages/${messageCode}?lang=${language}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<FocusOneMessage[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE_TABS', massageCode: messageCode, message: data, listLang: [] });
                });

            dispatch({ type: 'REQUEST_MESSAGE_TABS', massageCode: messageCode });
        } else if (appState && appState.focusonrMessgaeTabs && method === "POST") {
            fetch(`/v1/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<FocusOneMessage[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE_TABS', massageCode: messageCode, message: data, listLang: [] });
                });

            dispatch({ type: 'REQUEST_MESSAGE_TABS', massageCode: messageCode });
        }
    }
}

const unloadedState: FocusOneMessageTabsState = { message: [] , isLoading: false, language: '', listLang: [] };

export const reducer: Reducer<FocusOneMessageTabsState> = (state: FocusOneMessageTabsState | undefined, incomingAction: Action): FocusOneMessageTabsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MESSAGE_TABS':
            return {
                massageCode: action.massageCode,
                message: state.message,
                isLoading: true,
                listLang: state.listLang
            };
        case 'RECEIVE_MESSAGE_TABS':
            if (action.massageCode === state.massageCode) {
                return {
                    massageCode: action.massageCode,
                    message: action.message,
                    isLoading: false,
                    listLang: action.listLang
                };
            }
            break;
    }

    return state;
};