import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneMessage from '../store/FocusOneMessage';
import FocusOneMessageEditMode from './FocusoneMessageEdit';
import '../custom.css';
import { FaRegEdit } from "react-icons/fa";
import * as Models from '../models/Models';

type FocusOneMessageProps =
    FocusOneMessage.FocusOneMessageState
    & typeof FocusOneMessage.actionCreators
    & RouteComponentProps<{ messageId: string }>;

export interface ResponseAuthentication {
    message: string,
    token: string,
    first_name: string,
    last_name: string
}

class FocusOneMessagComponent extends React.PureComponent<FocusOneMessageProps, { isEdit: boolean, isAuthetication: boolean }> {

    auth: ResponseAuthentication | undefined;
    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);    

        this.state = {
            isEdit: false,
            isAuthetication: false
        }

        var _MESSAGE = localStorage.getItem('FOCUSONEMESSAGE')!;
        this.auth = JSON.parse(_MESSAGE);

        this.changeState = this.changeState.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
    }

    public componentDidMount() {   

        if (this.auth != null && this.auth != undefined) {
            this.setState({
                isAuthetication: true
            });
        }

        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        const messageCode = this.props.match.params.messageId;
        this.props.requestFocusOneMessage(messageCode, "GET", "", null, LANG);

        if (this.props.isLoading) {
            this.setState({
                isEdit: false
            });
        }

        console.log(this.props.isLoading);
    }

    public changeState(e: any) {
        this.setState({
            isEdit: true
        });
    }

    public render() {
        return (
            <div>
                {(!this.state.isEdit) ? this.defaultFocusOneMessagePage() : <FocusOneMessageEditMode messageList={this.props.message} messageId={this.props.match.params.messageId} />}
            </div>
        )
    }

    public defaultFocusOneMessagePage() {      

        return (
            <React.Fragment>
                <div className="container-fluid">
                    {this.props.isLoading &&
                        <div>
                            <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />{' '}
                        </div>
                    }

                    {!this.props.isLoading && this.state.isAuthetication == true &&

                        <div className="float-btn-edit" onClick={this.changeState}>
                            <div>
                                <FaRegEdit />
                            </div>
                        </div>

                    }

                    {!this.props.isLoading &&
                        this.props.message.map((item: FocusOneMessage.FocusOneMessage) =>
                            <div key={item.message_code}>
                                <h1 key={item.message_description}>{item.message_description}</h1>                                
                                <div dangerouslySetInnerHTML={this.createMarkup(item.message_document)} />
                            </div>
                        )
                    }
                </div>
            </React.Fragment>
        )        
    }

    createMarkup(html: any) {
        return { __html: html };
    }
}

export default connect(
    (state: ApplicationState) => state.focusOneMessage,
    FocusOneMessage.actionCreators
)(FocusOneMessagComponent as any);