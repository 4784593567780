import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as SystemLogs from '../store/SystemLogs';
import * as Models from '../models/Models';
import { Spinner } from 'reactstrap';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import SearchLogs from './SearchSystemLog';

type SystemLogsProps =
    SystemLogs.SystemLogState
    & typeof SystemLogs.actionCreators;

class SystemLogTables extends React.Component<any, SystemLogsProps>{

    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestSystemLog(LANG, '');
    }

    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-md-12 mt-4">
                        <SearchLogs handlePage={this.handlePage.bind(this)} />
                    </div>
                    <div className="col-md-12 mt-2">
                        {this.props.isLoading &&
                            <div>
                                <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />
                            </div>
                        }
                        {!this.props.isLoading && this.renderTables()}
                    </div>
                </div>

                
            </React.Fragment>
        );
    }

    handlePage(key: any) {
        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestSystemLog(LANG, key);
    }

    renderTables() {
        return (
            <React.Fragment>
                <table className='table table-bordered mt-2' aria-labelledby="tabelLabel">
                    <thead>
                        <tr style={{ background: "hsl(31, 67%, 67%)", color: "#fff" }}>
                            <th>solved</th>
                            <th>f1_error_code</th>
                            <th>menu</th>
                            <th>function</th>
                            <th>message</th>
                            <th>created_by</th>
                            <th>created_on</th>
                            <th>param</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.response.map((item: SystemLogs.logModel, index: any) =>
                            <tr key={index} className="table-tbody" >
                                <td>{item.solver}</td>
                                <td>{item.f1_error_code}</td>
                                <td>{item.menu}</td>
                                <td>{item.function}</td>
                                <td>{item.message}</td>
                                <td>{item.created_by}</td>
                                <td>{item.created_on}</td>
                                <td>
                                    <button className="btn"> <FaCloudDownloadAlt /> </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
            );
    }
}

export default connect((state: ApplicationState) => state.systemLogs,
    SystemLogs.actionCreators
)(SystemLogTables);
//export default FocusOneLog;