import * as React from 'react';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Hierarchy.css';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneMessageConfigs from '../store/MessageConfigCategoryType';
import * as Models from '../models/Models'

type FocusOneMessageConfig =
    FocusOneMessageConfigs.MessageConfigCategoryTypeState
    & typeof FocusOneMessageConfigs.actionCreators;

export interface MessageConfigCategoryTypeReq {
    id: string;
    language_id: string;
    message_category: string;
    created_on: string;
    created_by: string;
    updated_on: string;
    updated_by: string;
    type_list: Array<ListMessageConfigCategoryType>;
}

export interface ListMessageConfigCategoryType {
    id: string;
    language_id: string;
    message_type: string;
    created_on: string;
    created_by: string;
    updated_on: string;
    updated_by: string;
    message_list: Array<ListMessage>
}

export interface ListMessage {
    user_id: string;
    message_code: string;
    message_category_id: string;
    message_type_id: string;
    message_link: string;
    language_id: string;
    message_description: string;
    message_document: string;
    created_on: string;
    created_by: string;
    updated_on: string;
    updated_by: string;
}



class MessageConfigCategoryType extends React.Component<any, { isLoaded: boolean, items: [] }, FocusOneMessageConfig> {

    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any, language: string) {
        super(props, language);
        this.state = {
            isLoaded: false,
            items: []
        }
    }

    componentDidMount() {
        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestFocusOneMessages(LANG, "DEFAULT");

    }

    render() {


        if (this.props.isLoading) {
            return (
                <ul>
                    <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />{' '}
                </ul>
            );
        } else {
            return (
                <ol id="nestedlist" style={{ paddingBottom: "40px" }}>
                    {this.props.model.map((item: MessageConfigCategoryTypeReq, index: any) =>
                        <li style={{ fontSize: "10pt", fontWeight: "bold" }} key={index} > 
                            <Link to={`../../../?category=${item.id}`} style={{ color: "#282828" }} > {item.message_category}</Link>    
                            <ol>
                                {item.type_list.map((type: ListMessageConfigCategoryType, index_2: any) =>
                                    <li className="subHierachy" key={index_2}>
                                        <Link to={`../../../../?category=${item.id}&types=${type.id}`} style={{ color: "#282828" }} > {type.message_type}</Link>                                        
                                    </li>                                   
                                )}
                            </ol>                           
                        </li>
                    )}      
                </ol>
            );
        }
    }
}

export default connect(
    (state: ApplicationState) => state.messageConfigCategoryType,
    FocusOneMessageConfigs.actionCreators
)(MessageConfigCategoryType);
//export default MessageConfigCategoryType;
        //fetch(`v1/category/type/${LANG}`)
        //    .then(response => response.json())
        //    .then(data => {
        //        this.setState({
        //            isLoaded: true,
        //            items: data
        //        });
        //    });

//<ol>
//    {type.message_list.map((message: ListMessage) =>
//        <li className="subHierachy" style={{ fontSize: "9pt", fontWeight: "lighter" }} key={message.message_code}>
//            <Link to={`/message/${message.message_code}`} style={{ color: "#282828" }} > {message.message_code}</Link>
//        </li>
//    )}
//</ol>