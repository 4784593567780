import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface MessageConfigCategoryTypeState {
    isLoading: boolean;
    language?: string;
    loadstate: string;
    model: MessageConfigCategoryType[];
}

export interface MessageConfigCategoryType {
    id: string;
    language_id: string;
    message_category: string;
    created_on: string;
    created_by: string;
    updated_on: string;
    updated_by: string;
    type_list: Array<ListMessageConfigCategoryType>;
}

export interface ListMessageConfigCategoryType {
    id: string;
    language_id: string;
    message_category: string;
    created_on: string;
    created_by: string;
    updated_on: string;
    updated_by: string;
}

interface RequestMessageConfigCategoryTypeAction {
    type: 'REQUEST_MESSAGE_CONFIG_TYPE';
    language: string;
    loadstate: string;
}

interface ReceiveMessageConfigCategoryTypeAction {
    type: 'RECEIVE_MESSAGE_CONFIG_TYPE';
    model: MessageConfigCategoryType[];
    language: string;
    loadstate: string;
}

type KnownAction = RequestMessageConfigCategoryTypeAction | ReceiveMessageConfigCategoryTypeAction;

export const actionCreators = {
    requestFocusOneMessages: (language: string, loadstate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.messageConfigCategoryType && language !== appState.messageConfigCategoryType.language && loadstate === "DEFAULT") {

            fetch(`/v1/category/type/${language}`)
                .then(response => response.json() as Promise<MessageConfigCategoryType[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE_CONFIG_TYPE', model: data, language: language, loadstate: loadstate });
                });

            dispatch({ type: 'REQUEST_MESSAGE_CONFIG_TYPE', language: language, loadstate: loadstate });

        } else if (appState && appState.messageConfigCategoryType && loadstate === "UPDATE") {

            fetch(`v1/category/type/${language}`)
                .then(response => response.json() as Promise<MessageConfigCategoryType[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE_CONFIG_TYPE', model: data, language: language, loadstate: loadstate });
                });

            dispatch({ type: 'REQUEST_MESSAGE_CONFIG_TYPE', language: language, loadstate: loadstate });
        }
    }
};

const unloadedState: MessageConfigCategoryTypeState = { model: [], isLoading: false,  loadstate: "DEFAULT" };

export const reducer: Reducer<MessageConfigCategoryTypeState> = (state: MessageConfigCategoryTypeState | undefined, incomingAction: Action): MessageConfigCategoryTypeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_MESSAGE_CONFIG_TYPE':
            return {
                model: state.model,
                isLoading: true,
                loadstate: state.loadstate
            };
        case 'RECEIVE_MESSAGE_CONFIG_TYPE':           
            return {
                model: action.model,
                isLoading: false,
                loadstate: action.loadstate
            };     

        default:  return state;
    }
};