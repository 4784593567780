import * as React from 'react';
import './SignIn.css'
import { FaUserAlt, FaLock } from "react-icons/fa";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Authentication from '../store/Authentication';
import { Redirect } from "react-router";
import  SweetAlert  from 'react-bootstrap-sweetalert';


type AuthenticationProps =
    Authentication.AuthenticationState
    & typeof Authentication.actionCreators
    & AuthenticationBody;

export interface AuthenticationBody {
    username: string,
    password: string,
    isHideAlert: boolean
}

class SignIn extends React.PureComponent<AuthenticationProps, AuthenticationBody> {

    constructor(props: any) {
        super(props);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            username: "",
            password: "",
            isHideAlert: false
        };
    }

    handleSubmit(e: any) {
        var { username, password } = this.state;
        this.props.requestAuthentication({ username: username, password: password });
        
        e.preventDefault();
    }

    handleUsernameChange(e: any) {
        this.setState({
            username: e.target.value
        })
    }

    handlePasswordChange(e: any) {
        this.setState({
            password: e.target.value
        })
    }

    public render() {

        if (!this.props.isLoading) {
            if (this.props.response != undefined) {
                if (this.props.response!.message === "001") {
                    return (<Redirect to="/" />)
                } else {

                    if (this.props.response!.message != "success") {                       
                        this.setState({ isHideAlert: true });
                        this.props.response!.message = "success"
                    }
                    
                }
            } 
        } 

        return (
            <div className="container center">
                {!this.props.isLoading &&
                    <SweetAlert error title="Invalid Username or Password" show={this.state.isHideAlert} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />
                }
                <div className="center">
                    <div className="card">
                        <div className="card-body">
                            <form className="form-horizontal" onSubmit={this.handleSubmit}>    

                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><FaUserAlt /></div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Username" onChange={this.handleUsernameChange }></input>
                                </div>

                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><FaLock /></div>
                                    </div>
                                    <input type="password" className="form-control" id="inlineFormInputGroup" placeholder="Password" onChange={this.handlePasswordChange}></input>
                                </div>

                                <div>
                                    <button type="submit" className="btn btn-info btn-block">sign in</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
    onConfirm(response: any) {
        this.setState({
            isHideAlert: false
        });
    }
}

export default connect(
    (state: ApplicationState) => state.authentication,
    Authentication.actionCreators
)(SignIn as any);