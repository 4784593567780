import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface AuthenticationState {
    isLoading: boolean;
    requestBody?: RequestAunthentication;
    response?: ResponseAuthentication
}

export interface RequestAunthentication {
    username: string,
    password: string
}

export interface ResponseAuthentication {
    message: string,
    token: string,
    first_name: string,
    last_name: string
}

export interface ResponseMessage {
    message: string
}

interface RequestAuthenticationAction {
    type: 'REQUEST_AUTHENTITION';
    requestBody: RequestAunthentication;
}

interface ReceiveAuthenticationAction {
    type: 'RECEIVE_AUTHENTITION';
    requestBody: RequestAunthentication;
    response: ResponseAuthentication;
    responseCode: number,
    errormessage?: string
}

type KnownAction = RequestAuthenticationAction | ReceiveAuthenticationAction;

export const actionCreators = {
    requestAuthentication: (requestBody: RequestAunthentication): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication && requestBody !== appState.authentication.requestBody) {
            fetch(`/v1/authentication`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
                .then(response => {

                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                    }

                    return response.json() as Promise<ResponseAuthentication>
                }                
                )
                .then(data => {
                    localStorage.setItem('FOCUSONEMESSAGE', JSON.stringify(data));

                    dispatch({ type: 'RECEIVE_AUTHENTITION', requestBody: requestBody, response: data, responseCode: 200 });
                })
                .catch((error) => {

                    dispatch({ type: 'RECEIVE_AUTHENTITION', requestBody: requestBody, response: error, responseCode: 400, errormessage: "Authetication fail" });
                   
                });

            dispatch({ type: 'REQUEST_AUTHENTITION', requestBody: requestBody });
        }
    }
}

const unloadedState: AuthenticationState = { isLoading: false };

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState | undefined, incomingAction: Action): AuthenticationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUTHENTITION':
            return {
                requestBody: action.requestBody,
                response: state.response,
                isLoading: true
            };
        case 'RECEIVE_AUTHENTITION':
            if (action.requestBody === state.requestBody) {
                return {
                    requestBody: action.requestBody,
                    response: action.response,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};