import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface SmartSearchMessageState {
    isLoading: boolean;
    response: SearchMessage[];
    language: string;
    key: string;
    pageSize: number;
}

export interface SearchMessage {
    message_id: string,
    message_code: string,
    message_category_id: string,
    message_category: string,
    message_type_id: string,
    message_type: string,
    message_link: string,
    language_id: string,
    message_language: string,
    message_description: string,
    message_document: string,
    message_raw: string
}

interface RequestSmartSearchMessageAction {
    type: 'REQUEST_MESSAGE';
    key: string;
    language: string;
    pageSize: number;
}

interface ReceiveSmartSearchMessageAction {
    type: 'RECEIVE_MESSAGE';
    response: SearchMessage[];
    key: string;
    language: string;
    pageSize: number;
}

type KnownAction = RequestSmartSearchMessageAction | ReceiveSmartSearchMessageAction;

export const actionCreators = {
    requestSmartSearch: (key: string, pageSize: number, language: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.smartsearchMessage && language == appState.smartsearchMessage.language) {
            debugger
            fetch(`/v1/search?key=${key}&page_size=${pageSize}`)
                .then(response => response.json() as Promise<SearchMessage[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE', key: key, language: language, response: data, pageSize: pageSize});
                });

            dispatch({ type: 'REQUEST_MESSAGE', key: key, language: language, pageSize: pageSize });
        }        
    }
}

const unloadedState: SmartSearchMessageState = { isLoading: false, key: '', language: 'EN', pageSize: 20, response: [] };

export const reducer: Reducer<SmartSearchMessageState> = (state: SmartSearchMessageState | undefined, incomingAction: Action): SmartSearchMessageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MESSAGE':
            return {
                isLoading: true,
                key: state.key,
                language: state.language,
                pageSize: state.pageSize,
                response: state.response
            };

        case 'RECEIVE_MESSAGE':

            if (action.key === state.key) {
                return {
                    isLoading: false,
                    key: action.key,
                    language: action.language,
                    pageSize: action.pageSize,
                    response: action.response
                };
            }
            break;
    }

    return state;
};