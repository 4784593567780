import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface SystemLogState {
    isLoading: boolean;
    response: logModel[];
    language: string;
    key: string;
}

export interface logModel {
    id: number,
    menu: string,
    function: string,
    message: string,
    f1_error_code: string,
    solver: string,
    is_solved: boolean,
    parameter: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

interface RequestSystemLogAction {
    type: 'REQUEST_SYSTEM_LOGS';
    language: string;
    key: string;
}

interface ReceiveSystemLogAction {
    type: 'RECEIVE_SYSTEM_LOGS';
    response: logModel[];
    language: string;
    key: string;
}

type KnownAction = RequestSystemLogAction | ReceiveSystemLogAction;

export const actionCreators = {
    requestSystemLog: (language: string, key: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.systemLogs && language != appState.systemLogs.language) {
            fetch(`/v1/logs`)
                .then(response => response.json() as Promise<logModel[]>)
                .then(data => {
                    console.log(data);
                    dispatch({ type: 'RECEIVE_SYSTEM_LOGS', language: language, response: data, key: key });
                });

            dispatch({ type: 'REQUEST_SYSTEM_LOGS', language: language, key: key });
        }
        else if (appState && appState.systemLogs && key != appState.systemLogs.key) {
            fetch(`/v1/logs/search?key=${key}&pageSize=20`)
                .then(response => response.json() as Promise<logModel[]>)
                .then(data => {
                    console.log(data);
                    dispatch({ type: 'RECEIVE_SYSTEM_LOGS', language: language, response: data, key: key });
                });

            dispatch({ type: 'REQUEST_SYSTEM_LOGS', language: language, key: key });
        }
    }
}

const unloadedState: SystemLogState = { isLoading: false, language: '', response: [], key: '' };

export const reducer: Reducer<SystemLogState> = (state: SystemLogState | undefined, incomingAction: Action): SystemLogState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SYSTEM_LOGS':
            return {
                isLoading: true,
                language: state.language,
                response: state.response,
                key: state.key
            };

        case 'RECEIVE_SYSTEM_LOGS':          
                return {
                    isLoading: false,
                    language: action.language,
                    response: action.response,
                    key: action.key
                };

        default:   break;
    }

    return state;
};