import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface FocusOneMessageConfigurationState {
    message: FocusOneMessageConfig[];
    category?: string;
    types?: string;
    isLoading: boolean;
    language?: string;
    loadeState?: boolean;
}

export interface FocusOneMessageConfig {
    message_id: string,
    message_code: string,
    message_category_id: string,
    message_category: string,
    message_type_id: string,
    message_type: string,
    message_link: string,
    language_id: string,
    message_description: string,
    message_document: string
}

export interface RequestMessage {
    message_category_id: string,
    message_type_id: string,
    message_link: string,
    language_id: string,
    message_description: string,
    message_document: string
}

interface RequestFocusOneMessageConfigAction {
    type: 'REQUEST_MESSAGE_CONFIG';
    category: string;
    types: string;
    language: string;
    loadeState: boolean;
}

interface ReceiveFocusOneMessageConfigAction {
    type: 'RECEIVE_MESSAGE_CONFIG';
    message: FocusOneMessageConfig[];
    category: string;
    types: string;
    language: string;
    loadeState: boolean;
}

type KnownAction = RequestFocusOneMessageConfigAction | ReceiveFocusOneMessageConfigAction;

export const actionCreators = {
    requestFocusOneMessageConfig: (category: string, types: string, language: string, loadeState: boolean, method: string | null, message_id: string | null, body?: RequestMessage, search?: string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.focusontMessageConfig && category !== appState.focusontMessageConfig.category || types !== appState.focusontMessageConfig!.types && method == "GET") {

            callApi(category, types, dispatch, language, loadeState)

        } else if (appState && appState.focusontMessageConfig && language !== appState.focusontMessageConfig.language && method == "GET") {

            callApi(category, types, dispatch, language, loadeState)

        } else if (appState && appState.focusontMessageConfig && method == "DELETE") {

            fetch(`v1/messages/${message_id}?lang=${language}`, {
                method: 'DELETE'
            })
                .then(response => {
                    return response.json() as Promise<FocusOneMessageConfig[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_MESSAGE_CONFIG', language: language, message: data, category: category, types: types, loadeState: loadeState });
                });

            dispatch({ type: 'REQUEST_MESSAGE_CONFIG', language: language, category: category, types: types, loadeState: loadeState });

        } else if (appState && appState.focusontMessageConfig && method == "POST") {

            fetch(`v1/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response.json() as Promise<FocusOneMessageConfig[]>
                })
                .then(data => {

                    callApi(category, types, dispatch, language, loadeState)
                });

            dispatch({ type: 'REQUEST_MESSAGE_CONFIG', language: language, category: category, types: types, loadeState: loadeState });

        } else if (appState && appState.focusontMessageConfig && method == "SEARCH") {
            fetch(`/v1/search?key=${search}&page_size=20`)
                .then(response => {
                    return response.json() as Promise<FocusOneMessageConfig[]>
                })
                .then(data => {
                    //console.log(data);
                    dispatch({ type: 'RECEIVE_MESSAGE_CONFIG', language: language, message: data, category: category, types: types, loadeState: loadeState });
                });

            dispatch({ type: 'REQUEST_MESSAGE_CONFIG', language: language, category: category, types: types, loadeState: loadeState });
           
        }
    }
}

function callApi(category: string, types: string, dispatch: any, language: string, loadeState: boolean) {

    fetch(`v1/messages?category=${category}&type=${types}&lang=${language}`)
        .then(response => {
            return response.json() as Promise<FocusOneMessageConfig[]>
        })
        .then(data => {
            //console.log(data);
            dispatch({ type: 'RECEIVE_MESSAGE_CONFIG', language: language, message: data, category: category, types: types, loadeState: loadeState });
        });

    dispatch({ type: 'REQUEST_MESSAGE_CONFIG', language: language, category: category, types: types, loadeState: loadeState });
}

const unloadedState: FocusOneMessageConfigurationState = { message: [], isLoading: false, category: "", language: "", types: "", loadeState: false };

export const reducer: Reducer<FocusOneMessageConfigurationState> = (state: FocusOneMessageConfigurationState | undefined, incomingAction: Action): FocusOneMessageConfigurationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MESSAGE_CONFIG':
            return {
                category: state.category,
                language: state.language,
                types: state.types,
                message: state.message,
                loadeState: state.loadeState,
                isLoading: true
            };
        case 'RECEIVE_MESSAGE_CONFIG':
           
                return {
                    category: action.category,
                    message: action.message,
                    language: action.language,
                    types: action.types,
                    loadeState: action.loadeState,
                    isLoading: false
            };

        default: break;
    }

    return state;
};