import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as MessageConfigCategoryType from './MessageConfigCategoryType';
import * as FocusOneMessage from './FocusOneMessage';
import * as Authenthication from './Authentication';
import * as FocusOneCategories from './FocusOneCategories';
import * as FocusOneTypes from './FocusOneType';
import * as FocusOneLanguage from './FocusOneLanguage';
import * as FocusOneMessageConfig from './FocusOneMessageConfiguarion';
import * as FocusOneMessgaeTabs from './FocusOneMessageTabsData';
import * as SmartSearchMessage from './SmartSearchMessageConfiguration';
import * as SystemLogs from './SystemLogs';

export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    messageConfigCategoryType: MessageConfigCategoryType.MessageConfigCategoryTypeState | undefined;
    focusOneMessage: FocusOneMessage.FocusOneMessageState | undefined;
    authentication: Authenthication.AuthenticationState | undefined;
    focusOneCategories: FocusOneCategories.FocusOneCategoryState | undefined;
    focusOneType: FocusOneTypes.FocusOneTypeState | undefined;
    focusoneLanguage: FocusOneLanguage.FocusOneLanguageState | undefined;
    focusontMessageConfig: FocusOneMessageConfig.FocusOneMessageConfigurationState | undefined;
    focusonrMessgaeTabs: FocusOneMessgaeTabs.FocusOneMessageTabsState | undefined;
    smartsearchMessage: SmartSearchMessage.SmartSearchMessageState | undefined;
    systemLogs: SystemLogs.SystemLogState | undefined;
}

export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    messageConfigCategoryType: MessageConfigCategoryType.reducer,
    focusOneMessage: FocusOneMessage.reducer,
    authentication: Authenthication.reducer,
    focusOneCategories: FocusOneCategories.reducer,
    focusOneType: FocusOneTypes.reducer,
    focusoneLanguage: FocusOneLanguage.reducer,
    focusontMessageConfig: FocusOneMessageConfig.reducer,
    focusonrMessgaeTabs: FocusOneMessgaeTabs.reducer,
    smartsearchMessage: SmartSearchMessage.reducer,
    systemLogs: SystemLogs.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
