import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface FocusOneCategoryState {
    isLoading: boolean;
    language?: string;
    method: string;
    category: FocusOneCategory[],
    responseCode: number,
    errormessage?: string
}

export interface FocusOneCategory {
    id: string,
    language_id: string,
    message_category: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface ResponseMessage {
    message: string
}

export interface CreateFocusOneCategory {
    id: string,
    language_id: string,
    message_category: string
}

interface RequestFocusOneCategoryAction {
    type: 'REQUEST_CATEGORY';
    language: string;
    method: string;
}

interface ReceiveFocusOneCategoryAction {
    type: 'RECEIVE_CATEGORY';
    language: string;
    category: FocusOneCategory[];
    method: string;
    responseCode: number,
    errormessage?: string
}

type KnownAction = RequestFocusOneCategoryAction | ReceiveFocusOneCategoryAction;

export const actionCreators = {
    requestFocusOneCategory: (language: string, method: string, id: string | null, body: CreateFocusOneCategory | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.focusOneCategories && language !== appState.focusOneCategories.language && method == "GET") {
            fetch(`/v1/categories`)
                .then(response => response.json() as Promise<FocusOneCategory[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_CATEGORY', language: language, category: data, method: method, responseCode: 204 });
                });
            dispatch({ type: 'REQUEST_CATEGORY', language: language, method: method});

        } else if (appState && appState.focusOneCategories && method == "POST") {
            debugger;
            fetch(`v1/category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(body)
            })
            .then(response => {

                if (!response.ok) {
                    throw new Error(JSON.stringify(response.json() as Promise<ResponseMessage>));
                } 
                    return response.json() as Promise<FocusOneCategory[]>
                
            })
            .then(data => {
                dispatch({ type: 'RECEIVE_CATEGORY', language: language, category: data, method: method, responseCode: 200 });
            })
            .catch(error => {

                dispatch({ type: 'RECEIVE_CATEGORY', language: language, category: error, method: method, responseCode: 400, errormessage: error });
            });

            dispatch({ type: 'REQUEST_CATEGORY', language: language, method: method });

        } else if (appState && appState.focusOneCategories && method == "PUT") {
           
            fetch(`/v1/category/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<FocusOneCategory[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_CATEGORY', language: language, category: data, method: method, responseCode: 200 });
                });

            dispatch({ type: 'REQUEST_CATEGORY', language: language, method: method });

        } else if (appState && appState.focusOneCategories && method == "DELETE") {

            fetch(`/v1/category/${id}`, {
                method: 'DELETE'
            })
                .then(response => response.json() as Promise<FocusOneCategory[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_CATEGORY', language: language, category: data, method: method, responseCode: 200 });
                });

            dispatch({ type: 'REQUEST_CATEGORY', language: language, method: method });
        }
    }
}

function CallGetCategory(language: string, method: string, responsecode: number, dispath: any) {

}

const unloadedState: FocusOneCategoryState = { category: [], isLoading: false, method : "", responseCode: 100 };

export const reducer: Reducer<FocusOneCategoryState> = (state: FocusOneCategoryState | undefined, incomingAction: Action): FocusOneCategoryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CATEGORY':
            return {
                language: action.language,
                category: state.category,
                isLoading: true,
                method: action.method,
                responseCode: state.responseCode
            };
        case 'RECEIVE_CATEGORY':
            if (action.language === state.language) {
                return {
                    language: action.language,
                    category: action.category,
                    isLoading: false,
                    method: action.method,
                    responseCode: action.responseCode
                };
            }

            //if (action.method === "POST") {
            //    return {
            //        language: action.language,
            //        category: action.category,
            //        isLoading: false,
            //        method: action.method,
            //        responseCode: action.responseCode
            //    };
            //}
            break;
    }

    return state;
};