import * as React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Language from './FocusOneLanguage';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneTypeData from '../store/FocusOneType';
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneTypeProps =
    FocusOneTypeData.FocusOneTypeState
    & typeof FocusOneTypeData.actionCreators;

export interface FocusOneType {
    id: string,
    language_id: string,
    message_type: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface FocusOneLanguage {
    id: string,
    language_id: string,
    message_language: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface focusoneLanguage {
    language_shot: string,
    language: string
}


class UpdateTypeModal extends React.Component<any, { lang: string, type: string, id: string, isHideAlert: boolean, isHideAlertSuccess: boolean }, FocusOneTypeProps>{

    type: FocusOneType | undefined;
    language: FocusOneLanguage | undefined;
    stateSuccessAlert: boolean | undefined;
    langmodel: focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.handleUpdateType = this.handleUpdateType.bind(this);
        this.handleChangeLanguge = this.handleChangeLanguge.bind(this);
        this.handleInputType = this.handleInputType.bind(this);
        this.onConfirm = this.onConfirm.bind(this);

        //console.log(this.type);

        this.state = {
            lang: 'EN',
            type: '',
            id: '',
            isHideAlert: false,
            isHideAlertSuccess: false
        }

        this.stateSuccessAlert = false;
    }

    handleUpdateType(e: any) {
        //console.log(this.state.id);
        //console.log(this.state.lang);
        //console.log(this.state.type);

        var { id, lang, type } = this.state

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_shot;
        }

        this.props.requestFocusOneType(LANG, "PUT", id, { id: id, language_id: lang, message_type: type });

        this.stateSuccessAlert = true;
        e.preventDefault();
    }

    handleChangeLanguge(lang: any) {
        this.language = JSON.parse(lang);
        //this.setState({
        //    lang: this.language!.language_id
        //})
        this.state = {
            lang: this.language!.language_id,
            type: this.state.type,
            id: this.state.id,
            isHideAlert: false,
            isHideAlertSuccess: false
        }
        //console.log(this.state.lang);
    }

    handleInputType(e: any) {
        //this.setState({
        //    type: e.target.value
        //});
        this.state = {
            lang: this.state.lang,
            type: e.target.value,
            id: this.state.id,
            isHideAlert: false,
            isHideAlertSuccess: false
        }
    }

    render() {

        this.type = JSON.parse(this.props.typedata);

        if (this.type == undefined || this.type == null) {
            this.state = {
                lang: 'EN',
                type: '',
                id: '',
                isHideAlert: false,
                isHideAlertSuccess: false
            }
        } else {
            this.state = {
                lang: this.type.language_id,
                type: this.type.message_type,
                id: this.type.id,
                isHideAlert: false,
                isHideAlertSuccess: false
            }
        }

        if (!this.props.isLoading) {
            if (this.props.responseCode != undefined) {
                if (this.stateSuccessAlert != undefined && this.stateSuccessAlert) {
                    //console.log("responseCode");
                    //console.log(this.props.responseCode);
                    //debugger;
                    if (this.props.responseCode === 200) {

                        //this.setState({ isHideAlertSuccess: true });
                        this.state = {
                            lang: this.state.lang,
                            type: this.state.type,
                            id: this.state.id,
                            isHideAlert: false,
                            isHideAlertSuccess: true
                        }
                        this.stateSuccessAlert = false;

                    } else if (this.props.responseCode == 400) {

                        //this.setState({ isHideAlert: true });
                        this.state = {
                            lang: this.state.lang,
                            type: this.state.type,
                            id: this.state.id,
                            isHideAlert: true,
                            isHideAlertSuccess: false
                        }
                        this.stateSuccessAlert = false;
                    } else {
                        //this.setState({ isHideAlert: true });
                        this.state = {
                            lang: this.state.lang,
                            type: this.state.type,
                            id: this.state.id,
                            isHideAlert: true,
                            isHideAlertSuccess: false
                        }
                        this.stateSuccessAlert = false;
                    }
                }
            }
        } 

        return (
            <div onClick={this.props.loginmodals}>
                <Modal isOpen={this.props.loginmodal} toggle={this.props.loginmodals} className={this.props.className}>
                    <ModalHeader toggle={this.props.loginmodals}>Update Type</ModalHeader>
                    <form onSubmit={this.handleUpdateType}>
                    <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Update Name</label>
                                        <input type="text" className="form-control" defaultValue={this.state.type} onChange={this.handleInputType} />
                                    </div>
                                </div>
                            </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-info btn-block">Save</button>
                    </ModalFooter>
                    </form>
                </Modal>

                {!this.props.isLoading &&
                    <SweetAlert error title="Update Type Fail" show={this.state.isHideAlert} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />

                }
                {!this.props.isLoading &&
                    <SweetAlert success title="Update Type Success" show={this.state.isHideAlertSuccess} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />
                }
            </div>   
            );
    }

    onConfirm(response: any) {
        this.setState({
            isHideAlert: false,
            isHideAlertSuccess: false
        });
    }
}

export default connect(
    (state: ApplicationState) => state.focusOneType,
    FocusOneTypeData.actionCreators
)(UpdateTypeModal);

//export default UpdateTypeModal;

//<div className="col-md-12">
//    <div className="form-group">
//        <label>Language</label>
//        <Language selectLanguage={this.handleChangeLanguge} />
//    </div>
//</div>