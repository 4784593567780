import * as React from 'react';
import '../style/SmartSearch.css'
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneMessageConfigData from '../store/FocusOneMessageConfiguarion';
import * as Models from '../models/Models'

type FocusOneMessageConfig =
    FocusOneMessageConfigData.FocusOneMessageConfigurationState
    & typeof FocusOneMessageConfigData.actionCreators;

export interface SearchMessage {
    message_id: string,
    message_code: string,
    message_category_id: string,
    message_category: string,
    message_type_id: string,
    message_type: string,
    message_link: string,
    language_id: string,
    message_language: string,
    message_description: string,
    message_document: string,
    message_raw: string
}


class SmartSeach extends React.PureComponent<any, { isSearch: boolean, key: string, response: SearchMessage[] }, FocusOneMessageConfig> {

    private node: React.RefObject<HTMLInputElement>;
    langmodel: Models.focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            isSearch: false,
            key: '',
            response: []
        };

        this.node = React.createRef();
        this.handleSearch = this.handleSearch.bind(this)
        //this.handleOpenAutocomplete = this.handleOpenAutocomplete.bind(this)
    }

    handleSearch(e: any) {
        this.setState({
            key: e.target.value
        });

        //fetch(`/v1/search?key=${e.target.value}&page_size=20`)
        //    .then(response => response.json() as Promise<SearchMessage[]>)
        //    .then(data => {
        //        this.setState({
        //            response: data
        //        });
        //    });
    }

    //componentDidMount() {
    //    document.addEventListener('click', this.handleClick.bind(this), false);
    //}

    //componentWillUnmount() {
    //    document.removeEventListener('click', this.handleClick.bind(this), true);
    //}

    //handleClick(event: any) {
    //    if (this.node && !this.node.current?.contains(event.target)) {
    //        this.setState({
    //            isSearch: false
    //        });
    //    }
    //}

    //handleOpenAutocomplete(e: any) {
    //    this.setState({
    //        isSearch: true
    //    });
    //}

    handleLink(e: any) {

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_id;
        }

        this.props.requestFocusOneMessageConfig(this.props.category, this.props.types, LANG, !this.props.isLoading, "SEARCH", "", "", this.state.key);

        console.log(this.props)
        //this.props.handleKey(this.state.key);
    }

    render() {
        return (
            <React.Fragment >
                    <input type="text" style={{ width: '50%' }}
                        className="form-control"
                        onChange={this.handleSearch}
                    >
                </input>
                <button className="ml-2 btn btn-success" onClick={this.handleLink.bind(this)}>Search</button>                
            </React.Fragment>
            );
    }

    //renderSugesstion() {
    //    console.log(this.props.response);
    //    return (
    //        <React.Fragment>
    //            <ul className="list-group smart-search-result">
    //                {this.state.response.map((item: SmartSearchMessage.SearchMessage, index: any) =>
    //                    <li className="list-group-item" key={index}>
    //                        <Link to={`../../../../?key=${item.message_category_id}&types=${item.message_type_id}`} style={{ color: "#282828" }} > {item.message_code}: {item.message_description}</Link>   
    //                    </li>
    //                )}
    //            </ul>
    //        </React.Fragment>
    //        );
    //}
}


export default connect((state: ApplicationState) => state.focusontMessageConfig,
    FocusOneMessageConfigData.actionCreators
)(SmartSeach);
//export default SmartSeach;

//<React.Fragment >
//    <div className="form-gropu search-group" ref={this.node}>
//        <input type="text" style={{ width: '80%', borderRadius: '20px' }}
//            className="form-control"
//            onChange={this.handleSearch}
//        >
//        </input>
//    </div>
//</React.Fragment>