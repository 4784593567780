import * as React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Language from './FocusOneLanguage';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneCategoriesData from '../store/FocusOneCategories';
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneCategoryProps =
    FocusOneCategoriesData.FocusOneCategoryState
    & typeof FocusOneCategoriesData.actionCreators;

export interface FocusOneCategory {
    id: string,
    language_id: string,
    message_category: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface FocusOneLanguage {
    id: string,
    language_id: string,
    message_language: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface focusoneLanguage {
    language_shot: string,
    language: string
}

class UpdateCategoryModal extends React.Component<any, { lang: string, category: string, id: string, isHideAlert: boolean, isHideAlertSuccess: boolean }, FocusOneCategoryProps> {

    category: FocusOneCategory | undefined;
    language: FocusOneLanguage | undefined;
    stateSuccessAlert: boolean | undefined;
    langmodel: focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.handleUpdateCategory = this.handleUpdateCategory.bind(this);
        this.handleChangeLanguge = this.handleChangeLanguge.bind(this);
        this.handleInputCategory = this.handleInputCategory.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        
        this.state = {
            lang: '',
            category: '',
            id: '',
            isHideAlert: false,
            isHideAlertSuccess: false
        }

        this.stateSuccessAlert = false;
    }

    handleUpdateCategory(e: any) {

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_shot;
        }

        var { id, lang, category } = this.state

        this.props.requestFocusOneCategory(LANG, "PUT", id, { id: id, language_id: lang, message_category: category });

        this.stateSuccessAlert = true;
        e.preventDefault();
    }

    handleChangeLanguge(lang: any) {
        this.language = JSON.parse(lang);
        //this.setState({
        //    lang: this.language!.language_id
        //})
        this.state = {
            lang: this.language!.language_id,
            category: this.state.category,
            id: this.state.id,
            isHideAlert: false,
            isHideAlertSuccess: false
        }
        console.log(this.state.lang);
    }

    handleInputCategory(e: any) {
        //this.setState({
        //    category: e.target.value
        //});
        this.state = {
            lang: this.state.lang,
            category: e.target.value,
            id: this.state.id,
            isHideAlert: false,
            isHideAlertSuccess: false
        }
    }

    render() {

        this.category = JSON.parse(this.props.categorydata);

        if (this.category == undefined || this.category == null) {
            this.state = {
                lang: 'EN',
                category: '',
                id: '',
                isHideAlert: false,
                isHideAlertSuccess: false
            }
        } else {
            this.state = {
                lang: this.category.language_id,
                category: this.category.message_category,
                id: this.category.id,
                isHideAlert: false,
                isHideAlertSuccess: false
            }
        }

        if (!this.props.isLoading) {
            if (this.props.responseCode != undefined) {
                if (this.stateSuccessAlert != undefined && this.stateSuccessAlert) {
                    console.log("responseCode");
                    console.log(this.props.responseCode);
                    debugger;
                    if (this.props.responseCode === 200) {

                        //this.setState({ isHideAlertSuccess: true });
                        this.state = {
                            lang: this.state.lang,
                            category: this.state.category,
                            id: this.state.id,
                            isHideAlert: false,
                            isHideAlertSuccess: true
                        }
                        this.stateSuccessAlert = false;

                    } else if (this.props.responseCode == 400) {

                        //this.setState({ isHideAlert: true });
                        this.state = {
                            lang: this.state.lang,
                            category: this.state.category,
                            id: this.state.id,
                            isHideAlert: true,
                            isHideAlertSuccess: false
                        }
                        this.stateSuccessAlert = false;
                    } else {
                        //this.setState({ isHideAlert: true });
                        this.state = {
                            lang: this.state.lang,
                            category: this.state.category,
                            id: this.state.id,
                            isHideAlert: true,
                            isHideAlertSuccess: false
                        }
                        this.stateSuccessAlert = false;
                    }
                }
            }
        } 

        return (
            <div onClick={this.props.loginmodals}>
                <Modal isOpen={this.props.loginmodal} toggle={this.props.loginmodals} className={this.props.className}>
                    <ModalHeader toggle={this.props.loginmodals}>Update Category</ModalHeader>
                    <form onSubmit={this.handleUpdateCategory}>
                    <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Update Name</label>
                                        <input type="text" className="form-control" defaultValue={this.state.category} onChange={this.handleInputCategory} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Language</label>
                                        <Language selectLanguage={this.handleChangeLanguge} />
                                    </div>
                                </div>
                            </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-info btn-block">Save</button>
                    </ModalFooter>
                    </form>
                </Modal>

                {!this.props.isLoading &&
                    <SweetAlert error title="Update Category Fail" show={this.state.isHideAlert} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />

                }
                {!this.props.isLoading &&
                    <SweetAlert success title="Update Category Success" show={this.state.isHideAlertSuccess} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />
                }
            </div>        
            );
    }

    onConfirm(response: any) {
        this.setState({
            isHideAlert: false,
            isHideAlertSuccess: false
        });
    }
}

export default connect(
    (state: ApplicationState) => state.focusOneCategories,
    FocusOneCategoriesData.actionCreators
)(UpdateCategoryModal);

//export default UpdateCategoryModal;