import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Language from './FocusOneLanguage'
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as FocusOneTypeData from '../store/FocusOneType';
import SweetAlert from 'react-bootstrap-sweetalert';

type FocusOneTypeProps =
    FocusOneTypeData.FocusOneTypeState
    & typeof FocusOneTypeData.actionCreators;

export interface FocusOneLanguage {
    id: string,
    language_id: string,
    message_language: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface FocusOneCategory {
    id: string,
    language_id: string,
    message_category: string,
    created_on: string,
    created_by: string,
    updated_on: string,
    updated_by: string
}

export interface focusoneLanguage {
    language_shot: string,
    language: string
}

class CreateTypeModal extends React.Component<any, { lang: string, type: string, id: string, isHideAlert: boolean, isHideAlertSuccess: boolean }, FocusOneTypeProps> {

    categoryModel: FocusOneCategory | undefined;
    languageModel: FocusOneLanguage | undefined;
    stateSuccessAlert: boolean | undefined;
     langmodel: focusoneLanguage | undefined;

    constructor(props: any) {
        super(props);

        this.handleChangeLanguge = this.handleChangeLanguge.bind(this);
        this.handleInputType = this.handleInputType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputTypeId = this.handleInputTypeId.bind(this);
        this.onConfirm = this.onConfirm.bind(this);

        this.state = {
            lang: 'EN',
            type: '',
            id: '',
            isHideAlert: false,
            isHideAlertSuccess: false
        }

        this.stateSuccessAlert = false;
    }

    handleChangeLanguge(lang: any) {

        this.languageModel = JSON.parse(lang);

        //this.setState({
        //    lang: this.languageModel!.language_id
        //})
        this.state = {
            lang: this.languageModel!.language_id,
            type: this.state.type,
            id: this.state.id,
            isHideAlert: false,
            isHideAlertSuccess: false
        }
    }

    handleInputType(e: any) {
        this.setState({
            type: e.target.value
        })
    }

    handleSubmit(e: any) {

        var { lang, id, type } = this.state;

        var LANG = "EN"
        var _LANG = localStorage.getItem('FOCUSONEMESSAGE_LANGUAGE');

        if (_LANG != null) {
            this.langmodel = JSON.parse(_LANG);

            LANG = this.langmodel!.language_shot;
        }

        this.props.requestFocusOneType(LANG, "POST", "", { id: id, language_id: lang, message_type: type});
        
        this.stateSuccessAlert = true;
        e.preventDefault();
    }

    handleInputTypeId(e: any) {
        this.setState({
            id: e.target.value
        })
    }
     

    render() {

        if (!this.props.isLoading) {
            if (this.props.responseCode != undefined) {
                if (this.props.method != undefined && this.props.method == "POST") {
                    if (this.stateSuccessAlert != undefined && this.stateSuccessAlert) {
                        console.log("responseCode");
                        console.log(this.props.responseCode);
                        debugger;
                        if (this.props.responseCode === 200) {

                            //this.setState({ isHideAlertSuccess: true });
                            this.state = {
                                lang: this.state.lang,
                                type: this.state.type,
                                id: this.state.id,
                                isHideAlert: false,
                                isHideAlertSuccess: true
                            }
                            this.stateSuccessAlert = false;
                        } else if (this.props.responseCode == 400) {

                            //this.setState({ isHideAlert: true });
                            this.state = {
                                lang: this.state.lang,
                                type: this.state.type,
                                id: this.state.id,
                                isHideAlert: true,
                                isHideAlertSuccess: false
                            }
                            this.stateSuccessAlert = false;
                        } else {
                            //this.setState({ isHideAlert: true });
                            this.state = {
                                lang: this.state.lang,
                                type: this.state.type,
                                id: this.state.id,
                                isHideAlert: true,
                                isHideAlertSuccess: false
                            }
                            this.stateSuccessAlert = false;
                        }
                    }
                }
            }
        } 

        return (
            <div onClick={this.props.typemodals}>
                <Modal isOpen={this.props.typemodal} toggle={this.props.typemodals} className={this.props.className}>
                    <ModalHeader toggle={this.props.typemodals}>Create Type</ModalHeader>
                    <form onSubmit={this.handleSubmit}>
                    <ModalBody>                       
                            <div className="row">                               
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Type Id</label>
                                        <input type="text" className="form-control" onChange={this.handleInputTypeId} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Type Name</label>
                                        <input type="text" className="form-control" onChange={this.handleInputType} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Language</label>
                                        <Language selectLanguage={this.handleChangeLanguge}/>
                                    </div>
                                </div>
                            </div>
                        
                    </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-info btn-block" type="submit">Save</button>
                    </ModalFooter>
                    </form>
                </Modal>

                {!this.props.isLoading &&
                    <SweetAlert error title="Create Type Fail" show={this.state.isHideAlert} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />

                }
                {!this.props.isLoading &&
                    <SweetAlert success title="Create Type Success" show={this.state.isHideAlertSuccess} onConfirm={this.onConfirm} customButtons={
                        <React.Fragment>
                            <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
                        </React.Fragment>
                    } />
                }
            </div>
            );
    }

    onConfirm(response: any) {
        this.setState({
            isHideAlert: false,
            isHideAlertSuccess: false
        });
    }
}

export default connect(
    (state: ApplicationState) => state.focusOneType,
    FocusOneTypeData.actionCreators
)(CreateTypeModal);

//export default CreateTypeModal;